<div class="flex flex-col flex-auto min-w-0">
  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">
    <!-- CONTENT GOES HERE -->
    <div class="xl:p-10 h-300 min-h-300 max-h-300 rounded-2xl sm:p-0">
      <div class="container mx-auto">
        <div class="grid grid-cols-1 sm:grid-cols-3 gap-4">
          <div class="col-span-2">
            <div class="w-full">
              <fuse-card
                class="flex flex-col md:flex-row max-w-80 md:max-w-160 w-full filter-article ng-tns-c135-50 ng-star-inserted"
              >
                <div class="flex-0 w-80 md:w-64 ng-tns-c135-50 ng-star-inserted">
                  <img
                    src="../../../assets/graphics/calculate-tco.svg"
                    alt="Card cover image"
                    class="w-full h-full object-cover"
                  />
                </div>
                <div class="flex flex-col flex-auto m-8 mb-4 ng-tns-c135-50 ng-star-inserted">
                  <div class="text-xl font-semibold leading-tight">
                    {{ 'Orders.configuration.heading' | transloco }}
                  </div>
                  <div class="text-secondary font-medium text-md mt-1">
                    {{ 'Orders.configuration.description' | transloco }}
                  </div>
                  <div class="mt-8">
                    <button mat-raised-button class="capitalize" color="primary" (click)="redirectCalculate()">
                      <span>{{ 'Orders.configuration.startOrder' | transloco }}</span>
                      <mat-icon>arrow_forward</mat-icon>
                    </button>
                  </div>
                </div>
              </fuse-card>
            </div>
            <!-- <div class="w-1/3"></div> -->
          </div>
          <div class="col-span-3">
            <!-- new feature -->
            <div class="h-100 min-h-100 max-h-100 grid grid-cols-1 m-6 overflow-y-scroll rounded-lg">
              <div>
                <ng-container *ngIf="dataSource?.data.length > 0">
                  <table
                    mat-table
                    [dataSource]="dataSource"
                    class=""
                    matSortActive="CREATED_AT"
                    matSortDisableClear
                    matSortDirection="desc"
                    matSort
                    #empTbSort="matSort"
                  >
                    <!--- Note that these columns can be defined in any order.
                                The actual rendered columns are set as a property on the row definition" -->
                    <!-- Name Column -->
                    <ng-container matColumnDef="VEHICLE_NAME">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'Orders.table.heading.vehicleName' | transloco }}
                      </th>
                      <td mat-cell *matCellDef="let row" class="border-0">
                        {{ row?.asset?.extendable?.brand }} {{ row?.asset?.extendable?.model }}
                      </td>
                    </ng-container>

                    <!-- STATUS Column -->
                    <ng-container matColumnDef="STATUS">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'Orders.table.heading.status' | transloco }}
                      </th>
                      <td mat-cell *matCellDef="let row" class="border-0">{{ row?.status | transloco }}</td>
                    </ng-container>

                    <!-- CALCULATION Column -->
                    <ng-container matColumnDef="CALCULATION">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'Orders.table.heading.calculation' | transloco }}
                      </th>
                      <td
                        mat-cell
                        *matCellDef="let row"
                        class="border-0 font-semibold"
                        [ngClass]="
                          row?.status == 'TCO_OK' || row?.status == 'APPROVED_EMPLOYEE'
                            ? 'text-green-600'
                            : row?.status == 'TCO_MAYBE'
                            ? 'text-orange-500'
                            : 'text-red-600'
                        "
                      >
                        {{ row?.tcoCalculation?.value | currency : 'EUR' }}
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr
                      mat-row
                      *matRowDef="let row; columns: displayedColumns"
                      class="border-gray-200 border-b-[1px]"
                      (click)="configurationById(row)"
                    ></tr>
                  </table>
                </ng-container>
                <div *ngIf="!this.dataSource?.data.length" class="text-lg text-center">
                  {{ 'Orders.configuration.noConfig' | transloco }}
                </div>
                <!-- <mat-paginator #paginator [length]="totalData" [pageSizeOptions]="pageSizes" showFirstLastButtons></mat-paginator> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
