import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeService } from 'app/shared/employee.service';
import { UserService } from 'app/shared/user.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { take } from 'rxjs';
import { ViewChild } from '@angular/core';

@Component({
  selector: 'app-order-configuration',
  templateUrl: './order-configuration.component.html',
  styleUrls: ['./order-configuration.component.scss'],
  animations: [],
})
export class OrderConfigurationComponent implements OnInit {
  @ViewChild('fileInput')
  fileInputVariable: ElementRef;
  carInfoModelForm: FormGroup;
  carModelDataForm: FormGroup;
  toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];
  carBrandList: string[] = [];
  carModelList: string[] = [];
  carNameList: string[] = [];
  fuelTypeList: any[] = [];
  files: any;
  public isConfirmed: boolean = false;
  orderId;
  responsiblePerson;
  activeUser;
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  constructor(
    private employeeService: EmployeeService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public userService: UserService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private _translocoService: TranslocoService,
  ) {}
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.orderId = params['orderId'];
    });
    this.userService.fetchUserData().subscribe({
      next: result => {
        this.activeUser = result.data.me;
        if (this.activeUser?.orders.length) {
          this.responsiblePerson = this.activeUser?.orders[0]?.employee?.category?.carBenefit?.responsibleLeaseQuote;
        }
      },
      error: error => {
        console.log('Error', error);
      },
    });
    this.carInfoModelForm = this.formBuilder.group({
      carBrand: ['', Validators.required],
      carModel: ['', Validators.required],
      carName: ['', Validators.required],
    });
    this.carModelDataForm = this.formBuilder.group({
      cataloguePrice: ['', Validators.required],
      discount: ['', Validators.required],
      // duration: ['', Validators.required],
      electricityConsumption: ['', Validators.required],
      enginePower: ['', Validators.required],
      euronorm: ['', Validators.required],
      fiscalHorsepower: ['', Validators.required],
      fuelConsumption: ['', Validators.required],
      fuelType: ['', Validators.required],
      // leaseDuration: ['', Validators.required],
      leasePrice: ['', Validators.required],
      optionsPrice: ['', Validators.required],
      model: ['', Validators.required],
      name: ['', Validators.required],
      brand: ['', Validators.required],
      CO2EmissionNEDC: ['', Validators.required],
      CO2EmissionWLTP: ['', Validators.required],
    });

    this.employeeService.getCarBrandInfo().subscribe({
      next: result => {
        this.carBrandList = result.carModelInfoBrands;
      },
      error: error => {
        console.log('Error while Get Brands info', error);
      },
    });
    this.getfuelType();
  }
  onBrandChange(brand) {
    if (this.carInfoModelForm.value.carBrand.value != '') {
      this.employeeService.getCarModelInfo(brand.value).subscribe({
        next: modelDetail => {
          this.carModelList = modelDetail.carModelInfoModels;
        },
      });
    }
  }
  onModelChange(model) {
    if (this.carInfoModelForm.value.carModel.value != '') {
      this.employeeService.getCarNameInfo(this.carInfoModelForm.value.carBrand, model.value).subscribe({
        next: nameDetail => {
          this.carNameList = nameDetail.carModelInfoNames;
        },
      });
    }
  }
  onNameChange(name) {
    if (
      this.carInfoModelForm.value.carBrand.value != '' &&
      this.carInfoModelForm.value.carModel.value != '' &&
      this.carInfoModelForm.value.carName.value != ''
    ) {
      this.employeeService
        .getCarInfo(
          this.carInfoModelForm.value.carBrand,
          this.carInfoModelForm.value.carModel,
          this.carInfoModelForm.value.carName,
        )
        .subscribe({
          next: result => {
            this.setFormData(result);
          },
        });
    }
  }
  getfuelType() {
    this.employeeService.getFuleType().subscribe((result: any) => {
      this.fuelTypeList = result.data.fuelTypes;
    });
  }
  setFormData(carDetail) {
    let selecteFuelType = this.fuelTypeList.filter(item => item.code == carDetail.data.carModelInfo.fuelType)[0].name;
    this.carModelDataForm.setValue({
      cataloguePrice: carDetail.data.carModelInfo.cataloguePrice,
      discount: 0,
      electricityConsumption: carDetail.data.carModelInfo.electricityConsumption ?? 0,
      enginePower: carDetail.data.carModelInfo.enginePower ?? 0,
      euronorm: carDetail.data.carModelInfo.euronorm ?? 0,
      fiscalHorsepower: carDetail.data.carModelInfo.fiscalHorsepower ?? 0,
      fuelConsumption: carDetail.data.carModelInfo.fuelConsumption ?? 0,
      fuelType: selecteFuelType,
      // leaseDuration: 0,
      leasePrice: 0,
      // duration:0,
      optionsPrice: 0,
      model: carDetail.data.carModelInfo.model,
      name: carDetail.data.carModelInfo.name,
      brand: carDetail.data.carModelInfo.brand,
      CO2EmissionNEDC: carDetail.data.carModelInfo.CO2EmissionNEDC ?? 0,
      CO2EmissionWLTP: carDetail.data.carModelInfo.CO2EmissionWLTP ?? 0,
    });
  }
  calculateConfiguration() {
    this.carModelDataForm.value.fuelType = this.fuelTypeList.filter(
      item => item.name == this.carModelDataForm.value.fuelType,
    )[0].code;
    this.carModelDataForm.value.electricityConsumption = parseFloat(this.carModelDataForm.value.electricityConsumption);
    this.carModelDataForm.value.CO2EmissionNEDC = parseFloat(this.carModelDataForm.value.CO2EmissionNEDC);
    this.carModelDataForm.value.CO2EmissionWLTP = parseFloat(this.carModelDataForm.value.CO2EmissionWLTP);
    this.carModelDataForm.value.cataloguePrice = parseFloat(this.carModelDataForm.value.cataloguePrice);
    this.carModelDataForm.value.discount = parseFloat(this.carModelDataForm.value.discount);
    // this.carModelDataForm.value.duration =parseFloat(this.carModelDataForm.value.duration);
    this.carModelDataForm.value.enginePower = parseFloat(this.carModelDataForm.value.enginePower);
    this.carModelDataForm.value.euronorm = parseInt(this.carModelDataForm.value.euronorm);
    this.carModelDataForm.value.fiscalHorsepower = parseFloat(this.carModelDataForm.value.fiscalHorsepower);
    this.carModelDataForm.value.fuelConsumption = parseFloat(this.carModelDataForm.value.fuelConsumption);
    // this.carModelDataForm.value.leaseDuration =parseFloat(this.carModelDataForm.value.leaseDuration);
    this.carModelDataForm.value.leasePrice = parseFloat(this.carModelDataForm.value.leasePrice);
    this.carModelDataForm.value.optionsPrice = parseFloat(this.carModelDataForm.value.optionsPrice);
    let configData = {
      order: this.orderId,
      carAsset: {
        create: this.carModelDataForm.value,
      },
    };
    let documentInput;
    this.employeeService.createConfiguration(configData).subscribe({
      next: result => {
        documentInput = {
          company: this.employeeService.employeeDetail.companies[0].id,
          name: this.files[0]?.name,
          path: '',
          orderConfiguration: result.data.createOrderConfiguration.id,
          type: 'ORDER_LEASE_QUOTE',
        };
        if (this.files !== undefined || this.files.length) {
          this.employeeService
            .uploadDocument(documentInput, this.files)
            .then(() => {
              this.files = [];
              this.router.navigate(['/order', this.orderId, 'configuration', documentInput.orderConfiguration]);
            })
            .catch(error => {
              console.log('error while upload Document:', error);
              let ErrorMessage;
              this._translocoService
                .selectTranslate('Orders.configuration.uploadErrorMessage')
                .pipe(take(1))
                .subscribe(translation => {
                  ErrorMessage = translation;
                });
              this.carInfoModelForm.reset();
              this.carModelDataForm.reset();
              this.isConfirmed = false;
              this.fileInputVariable.nativeElement.value = '';
              this._snackBar.open(ErrorMessage, '', {
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
                duration: 2 * 1000,
              });
            });
        }
      },
      error: error => {
        let ErrorMessage;
        console.log('Error while create calculation', error);
        this._translocoService
          .selectTranslate('Orders.configuration.calculationErrorMessage')
          .pipe(take(1))
          .subscribe(translation => {
            ErrorMessage = translation;
          });
        this.isConfirmed = false;
        this.carInfoModelForm.reset();
        this.carModelDataForm.reset();
        this.fileInputVariable.nativeElement.value = '';
        this._snackBar.open(ErrorMessage, '', {
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
          duration: 2 * 1000,
        });
      },
    });
  }
  quoteVerify(checked) {
    if (checked) {
      this.isConfirmed = true;
    } else {
      this.isConfirmed = false;
    }
  }
  onFileChange(uploadedFile: File) {
    this.files = uploadedFile;
    if (!this.files.length) {
      this.isConfirmed = false;
    }
  }
  deleteFile(): void {
    this.files = [];
  }
}
