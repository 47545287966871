<div class="flex flex-col flex-auto min-w-0">
  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">
    <!-- CONTENT GOES HERE -->
    <div class="xl:p-4 h-300 min-h-300 max-h-300 rounded-2xl sm:p-0">
      <div class="grid grid-cols-1 md:grid-cols-4 gap-4">
        <div class="col-span-1 xl:col-span-2 lg:col-span-2 md:col-span-2">
          <fuse-card class="flex flex-col w-full filter-info">
            <div class="flex h-30">
              <fuse-card
                class="flex items-center w-full p-6 filter-info rounded-none bg-gradient-to-b from-primary-300 via-primary-400 to-primary-600"
                ><img
                  *ngIf="employeeService.employeeDetail?.avatar"
                  src="{{ employeeService.employeeDetail?.avatar }}"
                  alt="Card cover image"
                  class="w-20 h-20 mr-6 rounded-full"
                />
                <div class="flex flex-col">
                  <div class="text-2xl font-semibold leading-tight text-white">
                    {{ employeeService.employeeDetail?.firstName }}
                    {{ employeeService.employeeDetail?.lastName }}
                  </div>
                </div>
              </fuse-card>
            </div>
            <div class="flex flex-col px-8 pt-4 pb-6">
              <div class="flex text-primary text-xl font-semibold p-5">
                {{ 'Dashboard.MyDetails' | transloco }}
              </div>
              <div class="flex items-start justify-start px-5">
                <div>
                  <div class="flex text-secondary leading-tight mb-4">
                    <mat-icon svgIcon="mat_outline:email" class="text-primary"></mat-icon>
                    <div class="break-words pl-3">
                      {{ employeeService.employeeDetail?.email }}
                    </div>
                  </div>
                  <div class="flex text-secondary leading-tight mb-4" *ngIf="showAddress">
                    <mat-icon svgIcon="mat_outline:location_on" class="text-primary"></mat-icon>
                    <div class="break-words pl-3">
                      {{ employeeService.employeeDetail?.street }}
                      {{ employeeService.employeeDetail?.number }}
                      {{ employeeService.employeeDetail?.bus }}<br />
                      {{ employeeService.employeeDetail?.postalCode }}
                      {{ employeeService.employeeDetail?.city }}<br />
                      {{ employeeService.employeeDetail?.country?.name }}
                    </div>
                  </div>
                  <div class="flex text-secondary leading-tight mb-4">
                    <mat-icon svgIcon="mat_outline:call" class="text-primary"></mat-icon>
                    <div class="break-words pl-3">
                      {{ employeeService.employeeDetail?.phoneNumber }}
                    </div>
                  </div>
                  <div class="flex text-secondary leading-tight mb-4">
                    <mat-icon svgIcon="mat_outline:business_center" class="text-primary"></mat-icon>
                    <div class="break-words pl-3">
                      {{ 'Dashboard.Category' | transloco }} -
                      {{ employeeService.employeeDetail?.category?.name }}
                    </div>
                  </div>
                </div>
              </div>
              <hr class="w-full mt-3 mb-0" />
              <!-- MY BUDGET -->
              <div class="flex flex-wrap w-full mt-4">
                <div class="text-primary text-xl font-semibold p-5">
                  {{ 'Dashboard.MyBudget' | transloco }}
                </div>
                <!-- Budget Detail -->
                <div class="w-full">
                  <div class="px-5">
                    <div
                      class="flex text-secondary leading-tight mb-4"
                      *ngIf="employeeService.employeeDetail?.category?.carBenefit?.allowPurchase"
                    >
                      <mat-icon svgIcon="mat_outline:directions_car" class="text-primary"></mat-icon>
                      <div class="break-words pl-3 w-full text-center">
                        {{ employeeService.employeeDetail?.category?.carBenefit?.purchaseBudget | currency : 'EUR' }}
                      </div>
                      <div class="break-words pl-3 w-full">
                        {{ 'Dashboard.BudgetPerMonth' | transloco }}
                      </div>
                    </div>
                    <div
                      class="flex text-secondary leading-tight mb-4"
                      *ngIf="employeeService.employeeDetail?.category?.carBenefit?.allowLease"
                    >
                      <mat-icon svgIcon="mat_outline:directions_car" class="text-primary"></mat-icon>
                      <div class="break-words pl-3 w-full text-center">
                        {{ employeeService.employeeDetail?.category?.carBenefit?.leaseBudget | currency : 'EUR' }}
                      </div>
                      <div class="break-words pl-3 w-full">
                        {{ 'Dashboard.LeaseBudgetPerMonth' | transloco }}
                      </div>
                    </div>
                    <div
                      class="flex text-secondary leading-tight mb-4"
                      *ngIf="employeeService.employeeDetail?.category?.bicycleBenefit?.allowPurchase"
                    >
                      <mat-icon svgIcon="mat_outline:directions_bike" class="text-primary"></mat-icon>
                      <div class="break-words pl-3 w-full text-center">
                        {{
                          employeeService.employeeDetail?.category?.bicycleBenefit?.purchaseBudget | currency : 'EUR'
                        }}
                      </div>
                      <div class="break-words pl-3 w-full">
                        {{ 'Dashboard.BudgetPerMonth' | transloco }}
                      </div>
                    </div>
                    <div
                      class="flex text-secondary leading-tight mb-4"
                      *ngIf="employeeService.employeeDetail?.category?.bicycleBenefit?.allowLease"
                    >
                      <mat-icon svgIcon="mat_outline:directions_bike" class="text-primary"></mat-icon>
                      <div class="break-words pl-3 w-full text-center">
                        {{ employeeService.employeeDetail?.category?.bicycleBenefit?.leaseBudget | currency : 'EUR' }}
                      </div>
                      <div class="break-words pl-3 w-full">
                        {{ 'Dashboard.LeaseBudgetPerMonth' | transloco }}
                      </div>
                    </div>
                    <div
                      class="flex text-secondary leading-tight mb-4"
                      *ngIf="employeeService.employeeDetail?.category?.fuelCardBenefit"
                    >
                      <mat-icon svgIcon="mat_outline:local_gas_station" class="text-primary"></mat-icon>
                      <div class="break-words pl-3 w-full text-center">
                        {{
                          employeeService.employeeDetail?.category?.fuelCardBenefit?.monthlyBudget | currency : 'EUR'
                        }}
                      </div>
                      <div class="break-words pl-3 w-full">
                        {{ 'Dashboard.BudgetPerMonth' | transloco }}
                      </div>
                    </div>
                    <div
                      class="flex text-secondary leading-tight mb-4"
                      *ngIf="employeeService.employeeDetail?.category?.chargingSystemBenefit"
                    >
                      <mat-icon svgIcon="mat_outline:battery_charging_full" class="text-primary"></mat-icon>
                      <div class="break-words pl-3 w-full text-center">
                        {{ employeeService.employeeDetail?.category?.chargingSystemBenefit?.budget | currency : 'EUR' }}
                      </div>
                      <div class="break-words pl-3 w-full">
                        {{ 'Dashboard.BudgetPurchase' | transloco }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </fuse-card>
        </div>
        <div class="col-span-1 xl:col-span-2 lg:col-span-2 md:col-span-2">
          <fuse-card
            class="flex flex-col w-full p-4 filter-list"
            *ngIf="employeeService.employeeDetail?.companies[0].contactFleetManager"
          >
            <div class="flex flex-col mt-5 pr-3 pb-3 overflow-hidden">
              <div class="absolute bottom-0 right-0 w-24 h-24 -m-6">
                <mat-icon
                  class="icon-size-24 opacity-25 text-green-500 dark:text-green-400"
                  [svgIcon]="'heroicons_outline:chat-alt'"
                ></mat-icon>
              </div>
              <div class="flex items-center">
                <div class="flex flex-col">
                  <div class="text-lg font-medium tracking-tight leading-6 truncate text-primary">
                    {{ 'Dashboard.YourMobilityManager' | transloco }}
                  </div>
                </div>
              </div>
              <div class="flex flex-row flex-wrap mt-4 -mx-6">
                <div class="flex flex-col mx-6 my-3">
                  <div class="text-sm font-medium leading-none text-primary">
                    {{ 'Dashboard.Name' | transloco }}
                  </div>
                  <div class="mt-2 font-medium leading-none">
                    {{ employeeService.employeeDetail?.companies[0].contactFleetManager?.firstName }}
                    {{ employeeService.employeeDetail?.companies[0].contactFleetManager?.lastName }}
                  </div>
                </div>
                <div
                  class="flex flex-col mx-6 my-3"
                  *ngIf="employeeService.employeeDetail?.companies[0].contactMailbox"
                >
                  <div class="text-sm font-medium leading-none text-primary">
                    {{ 'Dashboard.Email' | transloco }}
                  </div>
                  <div class="mt-2 font-medium leading-none">
                    <a href="mailto:{{ employeeService.employeeDetail?.companies[0].contactMailbox }}">{{
                      employeeService.employeeDetail?.companies[0].contactMailbox
                    }}</a>
                  </div>
                </div>
                <div class="flex flex-col mx-6 my-3">
                  <div class="text-sm font-medium leading-none text-primary">
                    {{ 'Dashboard.Phone' | transloco }}
                  </div>
                  <div class="mt-2 font-medium leading-none">
                    <a href="tel:{{ employeeService.employeeDetail?.companies[0].contactFleetManager?.phoneNumber }}">
                      {{ employeeService.employeeDetail?.companies[0].contactFleetManager?.phoneNumber }}</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </fuse-card>
          <div class="w-full mt-2">
            <div>
              <mat-accordion>
                <!-- policy -->
                <fuse-card
                  class="flex items-center p-4 mb-4 filter-info cursor-pointer"
                  *ngIf="policyDocuments.length < 2"
                  [ngClass]="!policyDocuments.length ? 'pointer-events-none document-opacity' : ''"
                >
                  <mat-icon svgIcon="policy" class="icon-size-12 mr-6 text-primary"></mat-icon>
                  <div class="flex flex-col">
                    <div
                      [ngClass]="!policyDocuments.length ? 'pointer-events-none document-opacity' : ''"
                      class="text-2xl font-semibold leading-tight capitalize"
                      (click)="downloadDocument(policyDocuments[0]?.downloadUrl)"
                    >
                      {{ 'Dashboard.MobilityPolicy' | transloco }}
                    </div>
                  </div>
                </fuse-card>
                <mat-expansion-panel
                  *ngIf="policyDocuments.length > 1"
                  class="p-4"
                  (opened)="panelOpenState = true"
                  (closed)="panelOpenState = false"
                >
                  <mat-expansion-panel-header class="p-0 mr-4">
                    <mat-icon
                      svgIcon="heroicons_outline:document-text"
                      class="icon-size-12 mr-6 text-primary"
                    ></mat-icon>
                    <div class="flex flex-col justify-center">
                      <div class="text-2xl font-semibold leading-tight capitalize">
                        {{ 'Dashboard.MobilityPolicy' | transloco }}
                      </div>
                    </div>
                  </mat-expansion-panel-header>
                  <mat-divider class="mb-4"></mat-divider>
                  <div class="space-y-4">
                    <div class="flex justify-between" *ngFor="let policy of policyDocuments; index as i">
                      <div class="flex flex-col space-y-4 justify-center">
                        <div
                          (click)="downloadDocument(policy?.downloadUrl)"
                          class="text-base font-semibold leading-tight capitalize"
                        >
                          {{ policy.name }}
                        </div>
                      </div>
                      <button
                        mat-raised-button
                        class="capitalize"
                        color="primary"
                        (click)="downloadDocument(policy?.downloadUrl)"
                      >
                        <mat-icon [svgIcon]="'heroicons_outline:download'"></mat-icon>
                      </button>
                    </div>
                  </div>
                </mat-expansion-panel>
                <!-- identity card -->
                <fuse-card
                  *ngIf="identityCards.length < 2"
                  class="flex items-center p-4 mb-4 filter-info cursor-pointer"
                  [ngClass]="!identityCards.length ? 'pointer-events-none document-opacity' : ''"
                >
                  <mat-icon
                    svgIcon="heroicons_outline:identification"
                    class="icon-size-12 mr-6 text-primary"
                  ></mat-icon>
                  <div class="flex flex-col">
                    <div
                      [ngClass]="!identityCards.length ? 'pointer-events-none document-opacity' : ''"
                      (click)="downloadDocument(identityCards[0]?.downloadUrl)"
                      class="text-2xl font-semibold leading-tight capitalize"
                    >
                      {{ 'Dashboard.IdentityCard' | transloco }}
                    </div>
                  </div>
                </fuse-card>
                <mat-expansion-panel
                  class="p-4"
                  *ngIf="identityCards.length > 1"
                  (opened)="panelOpenState = true"
                  (closed)="panelOpenState = false"
                >
                  <mat-expansion-panel-header class="p-0 mr-4">
                    <mat-icon
                      svgIcon="heroicons_outline:document-text"
                      class="icon-size-12 mr-6 text-primary"
                    ></mat-icon>
                    <div class="flex flex-col justify-center">
                      <div class="text-2xl font-semibold leading-tight capitalize">
                        {{ 'Dashboard.IdentityCard' | transloco }}
                      </div>
                    </div>
                  </mat-expansion-panel-header>
                  <mat-divider class="mb-4"></mat-divider>
                  <div class="space-y-4">
                    <div class="flex justify-between" *ngFor="let identityCard of identityCards; index as i">
                      <div class="flex flex-col space-y-4 justify-center">
                        <div
                          (click)="downloadDocument(identityCard?.downloadUrl)"
                          class="text-base font-semibold leading-tight capitalize"
                        >
                          {{ identityCard.name }}
                        </div>
                      </div>
                      <button
                        mat-raised-button
                        class="capitalize"
                        color="primary"
                        (click)="downloadDocument(identityCard?.downloadUrl)"
                      >
                        <mat-icon [svgIcon]="'heroicons_outline:download'"></mat-icon>
                      </button>
                    </div>
                  </div>
                </mat-expansion-panel>
                <!-- driving licence -->
                <fuse-card
                  class="flex items-center p-4 mb-4 filter-info"
                  *ngIf="drivingLicences.length < 2"
                  [ngClass]="!drivingLicences.length ? 'pointer-events-none document-opacity' : ''"
                >
                  <mat-icon
                    svgIcon="heroicons_outline:identification"
                    class="icon-size-12 mr-6 text-primary"
                  ></mat-icon>
                  <div class="flex flex-col">
                    <div
                      [ngClass]="!drivingLicences.length ? 'pointer-events-none document-opacity' : ''"
                      (click)="downloadDocument(drivingLicences[0]?.downloadUrl)"
                      class="text-2xl font-semibold leading-tight capitalize"
                    >
                      {{ 'Dashboard.DriverLicense' | transloco }}
                    </div>
                  </div>
                </fuse-card>
                <mat-expansion-panel
                  class="p-4"
                  *ngIf="drivingLicences.length > 1"
                  (opened)="panelOpenState = true"
                  (closed)="panelOpenState = false"
                >
                  <mat-expansion-panel-header class="p-0 mr-4">
                    <mat-icon
                      svgIcon="heroicons_outline:document-text"
                      class="icon-size-12 mr-6 text-primary"
                    ></mat-icon>
                    <div class="flex flex-col justify-center">
                      <div class="text-2xl font-semibold leading-tight capitalize">
                        {{ 'Dashboard.DriverLicense' | transloco }}
                      </div>
                    </div>
                  </mat-expansion-panel-header>
                  <mat-divider class="mb-4"></mat-divider>
                  <div class="space-y-4">
                    <div class="flex justify-between" *ngFor="let drivingLicence of drivingLicences; index as i">
                      <div class="flex flex-col space-y-4 justify-center">
                        <div
                          (click)="downloadDocument(drivingLicence?.downloadUrl)"
                          class="text-base font-semibold leading-tight capitalize"
                        >
                          {{ drivingLicence.name }}
                        </div>
                      </div>
                      <button
                        mat-raised-button
                        class="capitalize"
                        color="primary"
                        (click)="downloadDocument(drivingLicence?.downloadUrl)"
                      >
                        <mat-icon [svgIcon]="'heroicons_outline:download'"></mat-icon>
                      </button>
                    </div>
                  </div>
                </mat-expansion-panel>

                <!-- Other Document -->
                <mat-expansion-panel class="p-4" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header class="p-0 mr-4">
                    <mat-icon
                      svgIcon="heroicons_outline:document-text"
                      class="icon-size-12 mr-6 text-primary"
                    ></mat-icon>
                    <div class="flex flex-col justify-center">
                      <div class="text-2xl font-semibold leading-tight capitalize">
                        {{ 'Dashboard.Other' | transloco }}
                      </div>
                    </div>
                  </mat-expansion-panel-header>
                  <mat-divider class="mb-4"></mat-divider>
                  <div class="space-y-4">
                    <div class="flex justify-between" *ngFor="let otherDoc of otherDocuments; index as i">
                      <div class="flex flex-col space-y-4 justify-center">
                        <div
                          (click)="downloadDocument(otherDoc?.downloadUrl)"
                          class="text-base font-semibold leading-tight capitalize"
                        >
                          {{ otherDoc.name }}
                        </div>
                      </div>
                      <button
                        mat-raised-button
                        class="capitalize"
                        color="primary"
                        (click)="downloadDocument(otherDoc?.downloadUrl)"
                      >
                        <mat-icon [svgIcon]="'heroicons_outline:download'"></mat-icon>
                      </button>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div class="flex flex-col items-end filter-info sm:w-full mt-2">
              <button mat-raised-button class="capitalize" color="primary" (click)="openDialog()">
                <mat-icon [svgIcon]="'heroicons_outline:cloud-upload'"></mat-icon>
                <span class="ml-2">{{ 'Dialog.Actions.UploadDocument' | transloco }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
