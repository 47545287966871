import { gql } from 'apollo-angular';
export const ME_QUERY = gql`
  query Me {
    me {
      companies {
        id
        logoUrl
        name
      }
      email
      firstName
      id
      lastName
      locale
      organisation {
        id
      }
      orders {
        configurations {
          id
          asset {
            id
            isVirtual
            extendable {
              ... on CarAsset {
                brand
                id
                licensePlate
                model
                vin
                licensePlate
                registrationDate
                fuelType
                fuelConsumption
                CO2EmissionWLTP
                registrationDate
                typeOwnership
                leaseStartDate
                purchaseStartDate
                duration
                leaseDuration
              }
            }
          }
          status
          tcoCalculation {
            value
            id
          }
        }
        employee {
          category {
            carBenefit {
              responsibleLeaseQuote
              id
            }
            id
          }
          id
        }
        orderStatus
        id
      }
      phoneNumber
      roles {
        id
        slug
      }
      category {
        carBenefit {
          allowLease
          allowPurchase
          responsibleLeaseQuote
        }
      }
    }
  }
`;
