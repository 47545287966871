import { Component, OnInit } from '@angular/core';
import { EmployeeService } from 'app/shared/employee.service';
import { UserService } from 'app/shared/user.service';

@Component({
  selector: 'app-my-garage',
  templateUrl: './my-garage.component.html',
  styleUrls: ['./my-garage.component.scss'],
})
export class MyGarageComponent implements OnInit {
  employeeDetail;
  carAsset;
  bicycleAsset;
  chargingSystemAsset;
  bikeExpirationDate;
  carExpirationDate;
  chargingSystemExpirationDate;
  carFuelType;
  carAssetDocuments;
  haveAsset = false;
  carAssetContactList = [];
  bikeAssetContactList = [];
  chargingSystemContactList = [];
  devideY = false;

  constructor(public userService: UserService, public employeeService: EmployeeService) { }

  ngOnInit(): void {
    if (window.innerWidth < 960) {
      this.devideY = true;
    }
    this.userService.fetchUserData().subscribe(result => {
      this.employeeService.getEmployeeDetailById(result?.data?.me?.id).subscribe((result: any) => {
        this.employeeService.employeeDetail = result?.data?.userById;
        this.getAssetsByCategory(this.employeeService.employeeDetail.assetsCurrent);
      });
    });
  }

  getAssetsByCategory(assetsArray) {
    if (!assetsArray.length) {
      return;
    }
    assetsArray.forEach(element => {
      switch (element.extendable?.__typename) {
        case 'CarAsset':
          this.haveAsset = true;
          this.carAsset = element;
          // this.carAssetContactList = element.serviceContacts
          this.carAssetContactList = [
            ...element.serviceContacts,
          ]
          if (element.serviceCollections) {
            for (const collection of element.serviceCollections) {
              this.carAssetContactList.push(...collection.serviceContacts);
            }
          }
          let extendable = this.carAsset?.extendable;
          if (extendable?.typeOwnership) {
            if (extendable?.typeOwnership == 'lease') {
              this.carExpirationDate = this.getExpirationDate(
                extendable.leaseStartDate,
                this.carAsset?.pivot?.endDate,
                extendable.leaseDuration,
              );
            } else {
              this.carExpirationDate = this.getExpirationDate(
                extendable.purchaseStartDate,
                this.carAsset?.pivot?.endDate,
                extendable.duration,
              );
            }
          }
          this.getfuelType(this.carAsset?.extendable?.fuelType);
          break;
        case 'BicycleAsset':
          this.haveAsset = true;
          this.bicycleAsset = element;
          // this.bikeAssetContactList = element.serviceContacts;
          this.bikeAssetContactList = [
            ...element.serviceContacts,
          ]
          if (element.serviceCollections) {
            for (const collection of element.serviceCollections) {
              this.bikeAssetContactList.push(...collection.serviceContacts);
            }
          }
          let extendableBicycle = this.bicycleAsset?.extendable;
          if (extendableBicycle?.typeOwnership) {
            if (extendableBicycle?.typeOwnership == 'lease') {
              this.bikeExpirationDate = this.getExpirationDate(
                extendableBicycle.leaseStartDate,
                this.bicycleAsset?.pivot?.endDate,
                extendableBicycle.leaseDuration,
              );
            } else {
              this.bikeExpirationDate = this.getExpirationDate(
                extendableBicycle.purchaseStartDate,
                this.bicycleAsset?.pivot?.endDate,
                extendableBicycle.duration,
              );
            }
          }
          break;
        case 'ChargingSystemAsset':
          this.haveAsset = true;
          this.chargingSystemAsset = element;
          this.chargingSystemContactList = element.serviceContacts;
          this.chargingSystemContactList = [
            ...element.serviceContacts,
          ]
          if (element.serviceCollections) {
            for (const collection of element.serviceCollections) {
              this.chargingSystemContactList.push(...collection.serviceContacts);
            }
          }
          this.chargingSystemExpirationDate = this.chargingSystemAsset?.pivot?.endDate
            ? this.employeeService.diff_months(new Date(), new Date(this.chargingSystemAsset?.pivot?.endDate))
            : '-';
          break;
        default:
          break;
      }
    });
  }
  getfuelType(fuelCode) {
    this.employeeService.getFuleType().subscribe((result: any) => {
      this.carFuelType = result.data.fuelTypes.filter(item => item.code == fuelCode)[0].name;
    });
  }
  downloadDocument(documents, type) {
    let downloadDocument;
    if (!documents.length) {
      return;
    }
    downloadDocument = documents.filter(document => document.type == type);
    if (downloadDocument.length) {
      this.downloadAll(downloadDocument);
    }
  }
  downloadAll(urls) {
    var link = document.createElement('a');
    link.setAttribute('download', null);
    link.setAttribute('target', '_blank');
    link.style.display = 'none';
    document.body.appendChild(link);
    for (var i = 0; i < urls.length; i++) {
      link.setAttribute('href', urls[i].downloadUrl);
      link.click();
    }
    document.body.removeChild(link);
  }

  getExpirationDate(date, pivotDate, duration) {
    let diffrence;
    if (pivotDate) {
      if (new Date() > new Date(pivotDate)) {
        return;
      }
      diffrence = this.employeeService.diff_months(new Date(), new Date(pivotDate));
    } else {
      if (new Date() > new Date(this.addMonthsToDate(new Date(date), duration))) {
        return;
      }
      diffrence = this.employeeService.diff_months(
        new Date(),
        new Date(this.addMonthsToDate(new Date(date), duration)),
      );
    }
    return diffrence;
  }
  addMonthsToDate(inputDate, monthsToAdd) {
    if (!(inputDate instanceof Date)) {
      throw new Error('Input date must be a Date object');
    }

    if (typeof monthsToAdd !== 'number') {
      throw new Error('Months to add must be a number');
    }

    const newDate = new Date(inputDate);
    newDate.setMonth(newDate.getMonth() + monthsToAdd + 1);
    const yyyy = newDate.getFullYear().toString().padStart(2, '0');
    const mm = newDate.getMonth().toString().padStart(2, '0');
    const dd = newDate.getDate().toString().padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  }
}
