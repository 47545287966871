import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { UserService } from 'app/shared/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
})
export class OrderComponent implements OnInit {
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['VEHICLE_NAME', 'STATUS', 'CALCULATION'];
  dataSource: MatTableDataSource<any>;
  searchKeywordFilter = new FormControl();
  pageSizes = [5, 10];
  totalData;
  lastPage;
  searchValid;
  activeUser: any = {};
  constructor(public userService: UserService, private router: Router) {
    this.dataSource = new MatTableDataSource<any>([]);
  }
  ngOnInit(): void {
    this.fetchUser();
  }
  fetchUser() {
    this.userService.fetchUserData().subscribe({
      next: result => {
        this.activeUser = result.data.me;
        let configArray = this.activeUser?.orders[0]?.configurations;
        for (let index = 0; index < configArray.length; index++) {
          if (
            configArray[index].status == 'DELIVERED' ||
            configArray[index].status == 'ORDERED' ||
            configArray[index].status == 'READY_TO_ORDER' ||
            configArray[index].status == 'APPROVED_EMPLOYEE'
          ) {
            if (this.router.url == '/order') {
              this.router.navigate(['/order', this.activeUser?.orders[0].id, 'configuration', configArray[index].id]);
              break;
            }
          }
        }
        this.dataSource = new MatTableDataSource(this.activeUser?.orders[0]?.configurations);
      },
      error: error => {
        console.log('Error', error);
      },
    });
  }
  redirectCalculate() {
    this.router.navigate(['/order', this.activeUser?.orders[0].id, 'configuration']);
  }
  configurationById(configuration) {
    this.router.navigate(['/order', this.activeUser?.orders[0].id, 'configuration', configuration.id]);
  }
}
