import { gql } from 'apollo-angular';
export const CAR_BRAND_INFO = gql`
  query {
    carModelInfoBrands
  }
`;

export const CAR_INFO = gql`
  query carModelInfo($brand: String!, $model: String!, $name: String!) {
    carModelInfo(brand: $brand, model: $model, name: $name) {
      CO2EmissionNEDC
      CO2EmissionWLTP
      batteryCapacity
      brand
      cataloguePrice
      electricityConsumption
      enginePower
      euronorm
      fiscalHorsepower
      fuelConsumption
      fuelType
      fullName
      id
      mass
      model
      name
    }
  }
`;
export const CAR_MODEL_INFO = gql`
  query carModelInfoModels($brand: String!) {
    carModelInfoModels(brand: $brand)
  }
`;
export const CAR_NAME_INFO = gql`
  query carModelInfoNames($brand: String!, $model: String!) {
    carModelInfoNames(brand: $brand, model: $model)
  }
`;
