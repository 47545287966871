<button mat-icon-button class="close-button close-icon" [mat-dialog-close]="true" (click)="onCloseDialog()">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<h2 mat-dialog-title class="header capitalize">{{ 'Dialog.Header.UploadFile' | transloco }}</h2>
<mat-dialog-content class="mat-typography">
  <fuse-alert
    class="mt-8"
    *ngIf="showAlert"
    [appearance]="'outline'"
    [showIcon]="false"
    [type]="alert.type"
    [@shake]="alert.type === 'error'"
  >
    {{ alert.message }}
  </fuse-alert>
  <form [formGroup]="form">
    <div class="pt-10 pb-10">
      <div>
        <div class="dropzone" fileDragDrop (filesChangeEmiter)="onFileChange($event)">
          <div class="text-wrapper">
            <div class="centered">
              <div>
                <mat-icon svgIcon="heroicons_outline:cloud-upload"></mat-icon>
              </div>
              <label for="file" class="text-2xl font-bold"
                >{{ 'Dialog.DragDrop' | transloco }}
                <span class="text-2xl font-bold text-indigo-500 underline">{{ 'Dialog.Browse' | transloco }}</span>
              </label>
              <input
                type="file"
                name="file"
                id="file"
                (change)="onFileChange($event.target.files)"
                accept="image/png, image/gif, image/jpeg,video/mp4,video/x-m4v,video/*,application/pdf,application/msword,
                application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint"
              />
              <div>
                <span class="text-xl text-secondary">{{ 'Dialog.SupportFormate' | transloco }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="files?.length" class="flex pt-2">
        <h3 mat-subheader>{{ 'Dialog.Files' | transloco }}</h3>
        <div class="row file-detail" *ngFor="let file of files; let ind = index">
          <div class="text-md text-secondary">
            {{ file.name }}
            <button
              mat-button
              class="uppercase text-md text-secondary"
              color="warn"
              cdkFocusInitial
              (click)="deleteFile()"
            >
              <mat-icon svgIcon="heroicons_outline:trash"></mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="font-medium text-primary mb-1">
      {{ 'Dialog.DocumentName' | transloco }}
    </div>
    <mat-form-field class="w-full">
      <input matInput formControlName="documentName" placeholder="{{ 'Dialog.PlaceholderDocumentName' | transloco }}" />
      <mat-error *ngIf="form?.get('documentName').hasError('required')">
        {{ 'Dialog.DocumentNameRequired' | transloco }}
      </mat-error>
    </mat-form-field>
    <div class="font-medium text-primary mb-1">{{ 'Dialog.Asset' | transloco }}</div>
    <mat-form-field class="w-full">
      <mat-select
        placeholder="{{ 'Dialog.PlaceholderSelectAsset' | transloco }}"
        formControlName="asset"
        (selectionChange)="onAssetsChange($event)"
      >
        <mat-option value="option" *ngFor="let asset of this.assets" [value]="asset">
          {{ asset.extendable?.__typename === 'FuelCardAsset' ? asset.serviceProvider?.name : '' }}
          {{
            asset.extendable?.__typename != 'ChargingSystemAsset'
              ? asset.extendable?.brand
              : asset.serviceProvider?.name
          }}
          {{
            asset.extendable?.__typename != 'ChargingSystemAsset'
              ? asset.extendable?.model
              : asset.extendable?.__typename
          }}
          {{
            asset.extendable?.__typename === 'ChargingSystemAsset' ? asset.extendable?.maxChargingLoad + 'kwh' : ''
          }}</mat-option
        >
        <mat-option value="PERSONAL_DOCUMENT">{{ 'Dialog.PersonalDocument' | transloco }}</mat-option>
      </mat-select>
      <mat-error *ngIf="form?.get('asset').hasError('required')">
        {{ 'Dialog.AssetsRequired' | transloco }}
      </mat-error>
    </mat-form-field>
    <div class="font-medium text-primary mb-3">
      {{ 'Dialog.DocumentType' | transloco }}
    </div>
    <mat-form-field class="w-full">
      <mat-select placeholder="{{ 'Dialog.PlaceholderSelectType' | transloco }}" formControlName="docType">
        <mat-option value="option" *ngFor="let type of this.documentType" [value]="type">{{
          type | doctype | transloco
        }}</mat-option>
      </mat-select>
      <mat-error *ngIf="form?.get('docType').hasError('required')">
        {{ 'Dialog.DocumentTypeRequired' | transloco }}
      </mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-raised-button
    class="uppercase"
    color="primary"
    cdkFocusInitial
    [disabled]="form.invalid"
    (click)="submit()"
  >
    {{ 'Dialog.Actions.Upload' | transloco }}
  </button>
</mat-dialog-actions>
