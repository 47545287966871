<div class="flex flex-col flex-auto min-w-0">
  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">
    <!-- CONTENT GOES HERE -->
    <div class="h-300 min-h-300 max-h-300 rounded-2xl sm:p-0">
      <div class="container mx-auto">
        <div *ngIf="myCards.length" class="col-span-1">
          <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-6 view-height">
            <div class="rounded-xl w-full">
              <div class="scrollable overflow-y-auto">
                <fuse-card class="flex shadow-md mb-4 w-full p-6 flex-col">
                  <div class="flex flex-row">
                    <img
                      class="flex-none w-10 h-10 md:mr-6 sm: mr-3 md:mb-0"
                      src="assets/icons/fuel-card.svg"
                      alt="Card cover image"
                    />
                    <div class="flex-1 flex-auto justify-center">
                      <div class="text-lg text-primary font-semibold leading-tight mb-1">
                        {{ cardContactList[0]?.serviceProvider?.name }}
                      </div>
                      <div class="leading-tight text-primary-600 font-semibold space mb-2">
                        {{ 'MyCardSerive.CardNumber' | transloco }}
                        <span class="text-secondary font-normal pl-4">{{
                          cardContactList[0]?.extendable?.number
                        }}</span>
                      </div>
                      <div class="leading-tight text-primary-600 font-semibold space">
                        {{ 'MyCardSerive.ExpirationDate' | transloco }}
                        <span class="text-secondary font-normal pl-4">{{
                          cardContactList[0]?.extendable?.expiryDate | date : 'MM/dd/yyyy'
                        }}</span>
                      </div>
                    </div>
                    <button
                      class="mb-10"
                      mat-icon-button
                      [matMenuTriggerFor]="cardMenu"
                      aria-label="Example icon-button with a menu"
                    >
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #cardMenu="matMenu" xPosition="before">
                      <button
                        *ngIf="card?.extendable?.pinCode !== ''"
                        mat-menu-item
                        (click)="openDialogWithTemplateRef(myDialog, cardContactList[0]?.extendable?.pinCode)"
                      >
                        <span>{{ 'MyCardSerive.PIN' | transloco }}</span>
                      </button>
                      <button mat-menu-item (click)="download(cardContactList[0]?.documents)">
                        <span>{{ 'MyCardSerive.Other' | transloco }}</span>
                      </button>
                    </mat-menu>
                  </div>
                  <app-contact-list [contacts]="cardContactList[0]?.serviceContacts"></app-contact-list>
                </fuse-card>
              </div>
              <ng-template #myDialog let-data>
                <div class="flex flex-col w-50 sm:w-100">
                  <div class="flex flex-col mt-5 pr-3 pb-3 overflow-hidden">
                    <div class="flex items-center justify-center">
                      <div class="flex flex-col">
                        <div class="text-2xl font-medium tracking-tight leading-10 truncate text-primary">
                          {{ 'MyCardSerive.PinCode' | transloco }}
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-row w-full flex-wrap justify-center">
                      <div class="flex flex-col mx-6 my-3">
                        <div class="mt-2 leading-none text-center font-medium text-xl">{{ data }}</div>
                      </div>
                    </div>
                    <div class="flex flex-row w-full flex-wrap mt-4 justify-center">
                      <div class="flex flex-col mx-6 my-3">
                        <button mat-raised-button color="primary" (click)="closeDialog()">
                          {{ 'MyCardSerive.Actions.Close' | transloco }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
        <!-- No Fuel card -->
        <div *ngIf="!myCards.length">
          <fuse-card
            class="flex flex-col md:flex-row max-w-80 md:max-w-160 w-full filter-article ng-tns-c135-50 ng-star-inserted"
          >
            <div class="flex-0 w-80 md:w-64 ng-tns-c135-50 ng-star-inserted">
              <img
                src="../../../assets/graphics/placeholder-assets.svg"
                alt="Card cover image"
                class="w-full h-full object-cover"
              />
            </div>
            <div class="flex flex-col flex-auto m-8 mb-4 ng-tns-c135-50 ng-star-inserted">
              <div class="text-xl font-semibold leading-tight">
                {{ 'MyCardSerive.EmptyCard' | transloco }}
              </div>
              <div class="text-secondary font-medium text-md mt-1">
                {{ 'MyCardSerive.NoAssignedCard' | transloco }}
              </div>
            </div>
          </fuse-card>
        </div>
      </div>
    </div>
  </div>
</div>
