<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
  class="dark bg-primary-500 print:hidden"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navigation.default"
  [opened]="!isScreenSmall"
>
  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationContentHeader>
    <div class="flex items-center w-70 p-4 pl-6">
      <!-- Logo -->
      <div class="flex items-center justify-center">
        <img src="assets/images/logo/logo-mobbud-light.svg" />
      </div>
      <!-- Components
            <div class="flex items-center ml-auto">
                <notifications></notifications>
                <user [showAvatar]="false"></user>
            </div>
            -->
    </div>
    <!-- User -->
    <div class="flex flex-col items-center w-full p-4">
      <div class="flex flex-col items-center justify-center w-full mt-6">
        <div class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium">
          {{ employeeService.employeeDetail?.firstName }}
          {{ employeeService.employeeDetail?.lastName }}
        </div>
        <div
          class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-primary-200"
        >
          {{ employeeService.employeeDetail?.email }}
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Navigation footer hook
    <ng-container fuseVerticalNavigationContentFooter>
        <div class="flex flex-0 items-center justify-center h-16 pr-6 pl-2 mt-2 mb-4 opacity-12">
            <img
                class="max-w-36"
                src="assets/images/logo/logo-text-on-dark.svg">
        </div>
    </ng-container>
    -->
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
  <!-- Header -->
  <div
    class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden"
  >
    <!-- Navigation toggle button -->
    <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
      <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
    </button>
    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
      <span class="font-medium text-secondary"
        >{{ 'Dashboard.Welcome' | transloco }}, {{ employeeService.employeeDetail?.firstName }}</span
      >
      <languages></languages>
      <!--
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <search [appearance]="'bar'"></search>
            <shortcuts></shortcuts>
            <messages></messages>
            -->
      <user [showAvatar]="false"></user>
      <!--
            <button
                class="lg:hidden"
                mat-icon-button
                (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
            </button>
            -->
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>

  <div
    class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden"
  >
    <span class="font-medium text-secondary">{{ 'Dashboard.FooterText' | transloco }} &copy; {{ currentYear }}</span>
  </div>
</div>

<!-- Quick chat
<quick-chat #quickChat="quickChat"></quick-chat>
-->
