<div class="flex flex-col flex-auto min-w-0">
  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">
    <!-- CONTENT GOES HERE -->
    <div class="h-300 min-h-300 max-h-300 rounded-2xl sm:p-0">
      <div class="container mx-auto">
        <div class="grid grid-cols-1 sm:grid-cols-3 gap-4" *ngIf="haveAsset">
          <div
            class="col-span-2 bg-white rounded-xl p-6"
            [ngClass]="{ 'h-fit-content': !carAssetContactList.length }"
            *ngIf="carAsset"
          >
            <div class="grid grid-cols-1 divide-x w-full mt-2">
              <div>
                <div class="w-full bg-white flex flex-row flex-wrap rounded-xl">
                  <div class="lg:w-4/5 sm:w-4/5">
                    <!-- Profile Card -->
                    <div
                      class="rounded-lg w-full flex flex-row flex-wrap antialiased"
                      style="background-size: cover; background-blend-mode: multiply"
                    >
                      <div>
                        <img src="assets/icons/car.svg" alt="Card cover image" class="h-20 mr-6" />
                      </div>
                      <div class="md:w-2/3 w-full px-3 flex flex-row flex-wrap">
                        <div class="grid grid-cols-1">
                          <div class="col-span-1 text-xl leading-tight text-primary-600">
                            {{ carAsset?.extendable?.brand }}
                            {{ carAsset?.extendable?.model }}
                          </div>
                          <button
                            class="bg-white text-red-500 text-lg border-2 border-red-500 active:bg-white font-bold uppercase px-2 rounded mr-4 mb-1 mt-2"
                            type="button"
                          >
                            {{ carAsset?.extendable?.licensePlate }}
                          </button>
                          <span *ngIf="carExpirationDate" class="text-secondary">
                            {{ 'MyGarage.ContractExpires' | transloco }}
                            <span *ngIf="carExpirationDate?.totalMonths > 0">
                              {{ carExpirationDate?.totalMonths }} {{ 'MyGarage.Months' | transloco }}
                            </span>
                            <span *ngIf="carExpirationDate?.remainingDays"
                              >{{ carExpirationDate?.remainingDays }}
                              <span *ngIf="carExpirationDate?.remainingDays > 1">{{ 'MyGarage.Days' | transloco }}</span
                              ><span *ngIf="carExpirationDate?.remainingDays == 1">{{
                                'MyGarage.Day' | transloco
                              }}</span>
                            </span>
                          </span>
                          <span *ngIf="!carExpirationDate" class="text-secondary">
                            {{ 'MyGarage.Expired' | transloco }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <!-- End Profile Card -->
                  </div>
                  <div class="lg:w-1/5 w-full text-end">
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu" xPosition="before">
                      <button mat-menu-item (click)="downloadDocument(carAsset?.documents, 'CONFORMITY_CERTIFICATE')">
                        <span>{{ 'MyGarage.ConformityCertificate' | transloco }}</span>
                      </button>
                      <button mat-menu-item (click)="downloadDocument(carAsset?.documents, 'REGISTRATION_CERTIFICATE')">
                        <span>{{ 'MyGarage.RegistrationCertificate' | transloco }}</span>
                      </button>
                      <button mat-menu-item (click)="downloadDocument(carAsset?.documents, 'INSURANCE_CERTIFICATE')">
                        <span>{{ 'MyGarage.InsuranceCertificate' | transloco }}</span>
                      </button>
                      <button mat-menu-item (click)="downloadDocument(carAsset?.documents, 'OTHER')">
                        <span>{{ 'MyGarage.Other' | transloco }}</span>
                      </button>
                    </mat-menu>
                  </div>
                </div>
              </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 divide-x bg-white pt-3 pb-14 rounded-lg">
              <div class="grid grid-cols-2 pt-5 pb-2 px-2">
                <div class="word-breck text-primary-600 font-bold text-base pb-3">
                  {{ 'MyGarage.Manufacturer' | transloco }}
                </div>
                <div class="word-breck text-base pb-3">
                  {{ carAsset?.extendable?.brand }}
                </div>
                <div class="word-breck font-bold text-primary-600 text-base pb-3">
                  {{ 'MyGarage.Model' | transloco }}
                </div>
                <div class="word-breck text-base pb-3">
                  {{ carAsset?.extendable?.model }}
                </div>
                <div class="word-breck font-bold text-primary-600 text-base pb-3">
                  {{ 'MyGarage.RegistrationDate' | transloco }}
                </div>
                <div class="word-breck text-base pb-3">
                  {{ carAsset?.extendable?.registrationDate | date : 'dd/MM/yyyy' }}
                </div>
                <div class="word-breck font-bold text-primary-600 text-base pb-3">
                  {{ 'MyGarage.NumberPlate' | transloco }}
                </div>
                <div class="word-breck text-base pb-3">
                  {{ carAsset?.extendable?.licensePlate }}
                </div>
              </div>
              <div class="grid grid-cols-2 pt-5 pb-2 lg:pl-10 sm:px-2">
                <div class="word-breck font-bold text-primary-600 text-base pb-3">
                  {{ 'MyGarage.Fueltype' | transloco }}
                </div>
                <div class="word-breck text-base pb-3">{{ carFuelType }}</div>
                <div class="word-breck font-bold text-primary-600 text-base pb-3">
                  {{ 'MyGarage.Fuelconsumption' | transloco }}
                </div>
                <div class="word-breck text-base pb-3">
                  {{ carAsset?.extendable?.fuelConsumption }} {{ 'liter' | transloco }} / 100km
                </div>
                <div class="word-breck font-bold text-primary-600 text-base pb-3">
                  {{ 'MyGarage.CO2Emission' | transloco }}
                </div>
                <div class="word-breck text-base pb-3">{{ carAsset?.extendable?.CO2EmissionWLTP }} g/km</div>
                <div class="word-breck font-bold text-primary-600 text-base pb-3">
                  {{ 'MyGarage.VIN' | transloco }}
                </div>
                <div class="word-breck text-base pb-3">
                  {{ carAsset?.extendable?.vin }}
                </div>
              </div>
            </div>
            <app-contact-list [contacts]="carAssetContactList" [splitColumns]="true"></app-contact-list>
          </div>
          <div class="col-span-1">
            <div class="ml-2">
              <!-- contact bicycle start -->
              <div class="grid grid-cols-1 w-full">
                <div class="rounded-xl">
                  <fuse-card class="flex shadow-md mb-4 p-6 w-full flex-col" *ngIf="bicycleAsset">
                    <div class="flex flex-row">
                      <img
                        class="flex-none w-10 h-10 md:mr-6 sm: mr-3 md:mb-0"
                        src="assets/icons/bicycle.svg"
                        alt="Card cover image"
                      />
                      <div class="flex-1 justify-center">
                        <div class="text-xl text-primary font-semibold leading-tight mb-1">
                          {{ bicycleAsset?.extendable?.brand }}
                          {{ bicycleAsset?.extendable?.model }}
                        </div>
                        <div *ngIf="bikeExpirationDate" class="text-md text-secondary leading-tight mb-1">
                          {{ 'MyGarage.ContractExpires' | transloco }}
                          <span *ngIf="bikeExpirationDate?.totalMonths > 0">
                            {{ bikeExpirationDate?.totalMonths }} {{ 'MyGarage.Months' | transloco }}
                          </span>
                          <span *ngIf="bikeExpirationDate?.remainingDays"
                            >{{ bikeExpirationDate?.remainingDays }}
                            <span *ngIf="bikeExpirationDate?.remainingDays > 1">{{ 'MyGarage.Days' | transloco }}</span
                            ><span *ngIf="bikeExpirationDate?.remainingDays == 1">{{
                              'MyGarage.Day' | transloco
                            }}</span>
                          </span>
                        </div>
                        <div *ngIf="!bikeExpirationDate" class="text-secondary">
                          {{ 'MyGarage.Expired' | transloco }}
                        </div>
                      </div>
                      <button
                        class="mb-10"
                        mat-icon-button
                        [matMenuTriggerFor]="bicycleMenu"
                        aria-label="Example icon-button with a menu"
                      >
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #bicycleMenu="matMenu" xPosition="before">
                        <button mat-menu-item (click)="downloadDocument(bicycleAsset?.documents, 'OTHER')">
                          <span>{{ 'MyGarage.Other' | transloco }}</span>
                        </button>
                      </mat-menu>
                    </div>
                    <app-contact-list [contacts]="bikeAssetContactList"></app-contact-list>
                  </fuse-card>
                </div>
              </div>
              <!-- contact bicycle end -->

              <!-- contact chargingSystem start -->
              <div class="grid grid-cols-1 w-full mt-2 rounded-xl">
                <div>
                  <!-- ############### -->
                  <fuse-card class="flex shadow-md mb-4 p-6 w-full flex-col" *ngIf="chargingSystemAsset">
                    <div class="flex flex-row">
                      <img
                        class="flex-none w-10 h-10 md:mr-6 sm: mr-3 md:mb-0"
                        src="assets/icons/charging-system.svg"
                        alt="Card cover image"
                      />
                      <div class="flex-1 flex-auto justify-center">
                        <div class="text-xl text-primary font-semibold leading-tight mb-1">
                          {{ 'MyGarage.ChargingSystem' | transloco }}
                        </div>
                        <div
                          *ngIf="chargingSystemExpirationDate != '-'"
                          class="text-md text-secondary leading-tight mb-4"
                        >
                          {{ 'MyGarage.ContractExpires' | transloco }}
                          <span *ngIf="chargingSystemExpirationDate?.totalMonths > 0">
                            {{ chargingSystemExpirationDate?.totalMonths }} {{ 'MyGarage.Months' | transloco }}
                          </span>
                          <span *ngIf="chargingSystemExpirationDate?.remainingDays"
                            >{{ chargingSystemExpirationDate?.remainingDays }}
                            <span *ngIf="chargingSystemExpirationDate?.remainingDays > 1">{{
                              'MyGarage.Days' | transloco
                            }}</span
                            ><span *ngIf="chargingSystemExpirationDate?.remainingDays == 1">{{
                              'MyGarage.Day' | transloco
                            }}</span></span
                          >
                        </div>
                        <div
                          *ngIf="chargingSystemExpirationDate == '-'"
                          class="text-md text-secondary leading-tight mb-4"
                        >
                          {{ chargingSystemExpirationDate }}
                        </div>
                        <div *ngIf="!chargingSystemExpirationDate" class="text-md text-secondary leading-tight mb-4">
                          {{ 'MyGarage.Expired' | transloco }}
                        </div>
                        <div class="text-md leading-tight text-primary-600 font-semibold space">
                          {{ 'MyGarage.Capacity' | transloco }}
                          <span class="text-secondary font-normal"
                            >{{ chargingSystemAsset?.extendable?.maxChargingLoad }}kWh</span
                          >
                        </div>
                        <div class="text-md leading-tight text-primary-600 font-semibold space">
                          {{ 'MyGarage.Supplier' | transloco }}
                          <span class="text-secondary font-normal">{{
                            chargingSystemAsset?.serviceProvider?.name
                          }}</span>
                        </div>
                      </div>
                      <button
                        class="mb-10"
                        mat-icon-button
                        [matMenuTriggerFor]="chargingMenu"
                        aria-label="Example icon-button with a menu"
                      >
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #chargingMenu="matMenu" xPosition="before">
                        <button mat-menu-item (click)="downloadDocument(chargingSystemAsset?.documents, 'OTHER')">
                          <span>{{ 'MyGarage.Other' | transloco }}</span>
                        </button>
                      </mat-menu>
                    </div>
                    <app-contact-list [contacts]="chargingSystemContactList"></app-contact-list>
                  </fuse-card>
                </div>
              </div>
              <!-- contact end -->
            </div>
          </div>
          <div class="lg:col-span-2"></div>
        </div>
        <!-- No Fuel card -->
        <div *ngIf="!haveAsset">
          <fuse-card
            class="flex flex-col md:flex-row max-w-80 md:max-w-160 w-full filter-article ng-tns-c135-50 ng-star-inserted"
          >
            <div class="flex-0 w-80 md:w-64 ng-tns-c135-50 ng-star-inserted">
              <img
                src="../../../assets/graphics/placeholder-assets.svg"
                alt="Card cover image"
                class="w-full h-full object-cover"
              />
            </div>
            <div class="flex flex-col flex-auto m-8 mb-4 ng-tns-c135-50 ng-star-inserted">
              <div class="text-2xl font-semibold leading-tight">
                {{ 'MyGarage.EmptyMobilityItem' | transloco }}
              </div>
              <div class="text-secondary font-medium text-md mt-1">
                {{ 'MyGarage.NoAssignedAsset' | transloco }}
              </div>
            </div>
          </fuse-card>
        </div>
      </div>
    </div>
  </div>
</div>
