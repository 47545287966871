import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeService } from 'app/shared/employee.service';
import { UserService } from 'app/shared/user.service';

@Component({
  selector: 'app-order-configurations-details',
  templateUrl: './order-configurations-details.component.html',
  styleUrls: ['./order-configurations-details.component.scss'],
})
export class OrderConfigurationsDetailsComponent implements OnInit {
  isConfirm: boolean = false;
  configurationId;
  orderId;
  myCalculationDetails;
  orderPlaced: boolean = false;
  showBackButton = true;
  responsiblePerson;
  constructor(
    public userService: UserService,
    private employeeService: EmployeeService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.configurationId = params['configurationId'];
      this.orderId = params['orderId'];
    });
    this.employeeService.orderConfigurationById(this.configurationId).subscribe({
      next: configDetails => {
        this.myCalculationDetails = configDetails.data.orderConfiguration;

        if (
          this.myCalculationDetails?.status == 'ORDERED' ||
          this.myCalculationDetails?.status == 'DELIVERED' ||
          this.myCalculationDetails?.status == 'READY_TO_ORDER'
        ) {
          this.showBackButton = false;
        }
      },
      error: error => {
        console.log('Error while Getting configurationData', error);
      },
    });
  }
  backtoOrder() {
    this.router.navigate(['/order']);
  }
  acceptCondition(event) {
    this.isConfirm = event;
  }
  placeOrder() {
    this.employeeService.orderConfigurationApproveEmployee(this.configurationId).subscribe({
      next: configDetails => {
        this.myCalculationDetails = configDetails.data.orderConfigurationEmployeeApprove;
        this.orderPlaced = true;
      },
      error: error => {
        console.log('Error while Getting configurationData', error);
        this.orderPlaced = false;
      },
    });
  }
  newCalulation() {
    this.router.navigate(['/order', this.orderId, 'configuration']);
  }
}
