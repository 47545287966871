<div class="flex flex-col flex-auto min-w-0">
  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">
    <!-- CONTENT GOES HERE -->
    <div class="xl:p-4 h-300 min-h-300 max-h-300 rounded-2xl sm:p-0">
      <div class="flex flex-wrap">
        <div class="w-full flex flex-wrap">
          <div class="lg:w-1/2 md:w-1/2 sm:w-full">
            <div class="grid grid-cols-1">
              <div class="flex text-4xl justify-center font-semibold pt-10">
                <div class="w-1/2">
                  <img src="{{ myCalculationDetails?.asset?.extendable?.thumbUrl }}" />
                </div>
              </div>
              <div
                *ngIf="
                  orderPlaced ||
                  myCalculationDetails?.status == 'APPROVED_EMPLOYEE' ||
                  myCalculationDetails?.status == 'READY_TO_ORDER'
                "
                class="flex text-4xl justify-center font-semibold"
              >
                <div class="">
                  <div class="text-primary capitalize">
                    {{ 'Orders.configuration.employeeApprove.successMessage' | transloco }}
                    <span class="text-black">,{{ employeeService?.employeeDetail?.firstName }} !</span>
                  </div>
                  <div>{{ 'Orders.configuration.employeeApprove.successdetailTwo' | transloco }}</div>
                  <div>{{ 'Orders.configuration.employeeApprove.successdetailThree' | transloco }}</div>
                  <div class="text-primary">
                    {{ 'Orders.configuration.employeeApprove.successdetailFour' | transloco }}
                  </div>
                </div>
              </div>
              <div
                *ngIf="!orderPlaced && !(myCalculationDetails?.status == 'APPROVED_EMPLOYEE')"
                class="flex text-4xl justify-center font-semibold"
                [ngSwitch]="myCalculationDetails?.status"
              >
                <div *ngSwitchCase="'TCO_OK'">
                  <div>
                    {{ 'Orders.configuration.tcoOK.hi' | transloco }}
                    <span class="text-primary">{{ employeeService?.employeeDetail?.firstName }},</span>
                  </div>
                  <div>
                    <span class="text-primary">{{ 'Orders.configuration.tcoOK.good' | transloco }}</span>
                    {{ 'Orders.configuration.tcoOK.news' | transloco }},
                  </div>
                  <div>{{ 'Orders.configuration.tcoOK.successMessage' | transloco }}</div>
                </div>
                <div *ngSwitchCase="'TCO_MAYBE'">
                  <div>
                    {{ 'Orders.configuration.tcoOK.hi' | transloco }}
                    <span class="text-orange-400">{{ employeeService?.employeeDetail?.firstName }},</span>
                  </div>
                  <div>
                    <span class="text-orange-400">{{
                      'Orders.configuration.tcoMayBe.successMessage' | transloco
                    }}</span>
                  </div>
                  <div>{{ 'Orders.configuration.tcoMayBe.successMessageTwo' | transloco }}</div>
                  <div>{{ 'Orders.configuration.tcoMayBe.successMessageThree' | transloco }}</div>
                  <div>{{ 'Orders.configuration.tcoMayBe.successMessageFour' | transloco }}</div>
                </div>
                <div *ngSwitchCase="'TCO_NO'">
                  <div>
                    {{ 'Orders.configuration.tcoOK.hi' | transloco }}
                    <span class="text-rose-700">{{ employeeService?.employeeDetail?.firstName }},</span>
                  </div>
                  <div>
                    <span class="text-rose-700">{{ 'Orders.configuration.tcoNO.successMessage' | transloco }},</span>
                  </div>
                  <div>{{ 'Orders.configuration.tcoNO.successMessageTwo' | transloco }}</div>
                  <div>{{ 'Orders.configuration.tcoNO.successMessageThree' | transloco }}</div>
                  <div>
                    <button mat-raised-button class="capitalize" color="primary" (click)="newCalulation()">
                      <span>{{ 'Orders.configuration.newCalculation' | transloco }}</span>
                    </button>
                  </div>
                </div>
                <div *ngSwitchCase="'DECLINED_FM'">
                  <div>
                    {{ 'Orders.configuration.tcoOK.hi' | transloco }}
                    <span class="text-rose-700">{{ employeeService?.employeeDetail?.firstName }},</span>
                  </div>
                  <div>
                    <span class="text-rose-700">{{ 'Orders.configuration.tcoNO.successMessage' | transloco }},</span>
                  </div>
                  <div>{{ 'Orders.configuration.fleetManager.declineMessage' | transloco }}</div>
                  <div>
                    <button mat-raised-button class="capitalize" color="primary" (click)="newCalulation()">
                      <span>{{ 'Orders.configuration.newCalculation' | transloco }}</span>
                    </button>
                  </div>
                </div>
                <div *ngSwitchCase="'ORDERED'">
                  <div class="text-primary capitalize">
                    {{ 'Orders.configuration.ordered.successMessage' | transloco }}
                    <span class="text-black">,{{ employeeService?.employeeDetail?.firstName }} !</span>
                  </div>
                  <div>
                    <span class="text-primary">{{ 'Orders.configuration.ordered.successMessageTwo' | transloco }}</span>
                  </div>
                </div>
                <div *ngSwitchCase="'DELIVERED'">
                  <div class="text-primary capitalize">
                    {{ 'Orders.configuration.ordered.successMessage' | transloco }}
                    <span class="text-black">,{{ employeeService?.employeeDetail?.firstName }} !</span>
                  </div>
                  <div>
                    <span class="text-primary">{{
                      'Orders.configuration.ordered.deliverSuccessMessage' | transloco
                    }}</span>
                  </div>
                </div>
                <div
                  *ngSwitchCase="
                    ['LEASE_QUOTE_REQUESTED', 'LEASE_QUOTE_NEEDED'].includes(myCalculationDetails?.status)
                      ? myCalculationDetails?.status
                      : !myCalculationDetails?.status
                  "
                >
                  <div>
                    {{ 'Orders.configuration.tcoOK.hi' | transloco }}
                    <span class="text-orange-400">{{ employeeService?.employeeDetail?.firstName }},</span>
                  </div>
                  <div>{{ 'Orders.configuration.leaseQuote.successMessage' | transloco }}</div>
                  <div>{{ 'Orders.configuration.leaseQuote.successMessageTwo' | transloco }}</div>
                  <div>{{ 'Orders.configuration.leaseQuote.successMessageThree' | transloco }}</div>
                </div>
              </div>
              <div *ngIf="myCalculationDetails?.status == 'TCO_OK'" class="flex justify-center pt-18">
                <div class="w-full px-5 py-2">
                  <mat-checkbox class="example-margin" (change)="acceptCondition($event.checked)">{{
                    'Orders.configuration.tcoOK.confirmation' | transloco
                  }}</mat-checkbox>
                  <br />
                  <button
                    mat-raised-button
                    class="capitalize"
                    color="primary"
                    [disabled]="!isConfirm"
                    (click)="placeOrder()"
                  >
                    <span>{{ 'Orders.configuration.order' | transloco }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="lg:w-1/3 md:w-1/3 sm:w-full pt-10">
            <fuse-card class="flex flex-col w-full filter-info">
              <div class="flex flex-col px-8 pt-4 pb-6">
                <div class="px-5">
                  <div class="text-2xl font-semibold mb-4">
                    {{ 'Orders.configuration.details.myCalculation' | transloco }}
                  </div>
                  <div>
                    <div class="flex text-secondary leading-tight mb-4">
                      <div class="break-words pl-3 w-full text-left font-medium text-primary">
                        {{ 'Orders.configuration.details.brand' | transloco }}
                      </div>
                      <div class="break-words pl-3 w-full">
                        {{ myCalculationDetails?.asset?.extendable?.brand }}
                      </div>
                    </div>
                    <div class="flex text-secondary leading-tight mb-4">
                      <div class="break-words pl-3 w-full text-left font-medium text-primary">
                        {{ 'Orders.configuration.details.model' | transloco }}
                      </div>
                      <div class="break-words pl-3 w-full">
                        {{ myCalculationDetails?.asset?.extendable?.model }}
                      </div>
                    </div>
                    <div class="flex text-secondary leading-tight mb-4">
                      <div class="break-words pl-3 w-full text-left font-medium text-primary">
                        {{ 'Orders.configuration.details.catalogPrice' | transloco }}
                      </div>
                      <div class="break-words pl-3 w-full">
                        {{ myCalculationDetails?.asset?.extendable?.cataloguePrice | currency : 'EUR' }}
                      </div>
                    </div>
                    <div class="flex text-secondary leading-tight mb-4">
                      <div class="break-words pl-3 w-full text-left font-medium text-primary">
                        {{ 'Orders.configuration.details.CO2' | transloco }}
                      </div>
                      <div class="break-words pl-3 w-full">
                        {{ myCalculationDetails?.asset?.extendable?.CO2EmissionWLTP }}
                        {{ 'Orders.configuration.details.co2scale' | transloco }}
                      </div>
                    </div>
                    <div class="flex text-secondary leading-tight mb-4">
                      <div class="break-words pl-3 w-full text-left font-medium text-primary">
                        {{ 'Orders.configuration.details.BIK' | transloco }}
                      </div>
                      <div class="break-words pl-3 w-full">
                        {{ myCalculationDetails?.tcoCalculation?.benefitInKind | currency : 'EUR' }}
                      </div>
                    </div>
                    <div class="flex text-secondary leading-tight mb-4">
                      <div class="break-words pl-3 w-full text-left font-medium text-primary">
                        {{ 'Orders.configuration.details.calculatedTCO' | transloco }}
                      </div>
                      <div class="break-words pl-3 w-full">
                        <div *ngIf="myCalculationDetails?.asset?.extendable?.typeOwnership == 'purchase'">
                          {{
                            myCalculationDetails?.tcoCalculation?.value /
                              myCalculationDetails?.asset?.extendable?.duration | currency : 'EUR'
                          }}
                          {{ 'Orders.configuration.details.perMonth' | transloco }}
                        </div>
                        <div *ngIf="myCalculationDetails?.asset?.extendable?.typeOwnership == 'lease'">
                          {{ myCalculationDetails?.tcoCalculation?.value | currency : 'EUR' }}
                          {{ 'Orders.configuration.details.perMonth' | transloco }}
                        </div>
                      </div>
                    </div>
                    <div class="flex text-secondary leading-tight mb-4" *ngIf="showBackButton">
                      <div class="break-words pl-3 w-full text-left font-medium text-primary text-2xl">
                        <button mat-raised-button class="capitalize" color="primary" (click)="backtoOrder()">
                          <mat-icon>keyboard_backspace</mat-icon>
                          <span>{{ 'Orders.configuration.details.back' | transloco }}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fuse-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
