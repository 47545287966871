import { gql } from 'apollo-angular';
export const ORDER_CONFIGURATION_APPROVE_EMPLOYEE = gql`
  mutation OrderConfigurationEmployeeApprove($input: OrderConfigurationEmployeeApproveInput!) {
    orderConfigurationEmployeeApprove(input: $input) {
      id
      status
      tcoCalculation {
        benefitInKind
        id
        taxDeduction
        type
        value
      }
      asset {
        id
        isVirtual
        extendable {
          ... on CarAsset {
            id
            model
            brand
            typeOwnership
            cataloguePrice
            CO2EmissionNEDC
            CO2EmissionWLTP
            duration
          }
        }
      }
    }
  }
`;
