import { Component, OnInit, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EmployeeService } from 'app/shared/employee.service';
import { UserService } from 'app/shared/user.service';

@Component({
  selector: 'app-my-cards-and-services',
  templateUrl: './my-cards-and-services.component.html',
  styleUrls: ['./my-cards-and-services.component.scss'],
})
export class MyCardsAndServicesComponent implements OnInit {
  myCards = [];
  cardContactList = [];
  constructor(public userService: UserService, public employeeService: EmployeeService, private dialog: MatDialog) { }
  ngOnInit(): void {
    this.userService.fetchUserData().subscribe(result => {
      this.employeeService.getEmployeeDetailById(result?.data?.me?.id).subscribe((result: any) => {
        this.employeeService.employeeDetail = result?.data?.userById;
        this.getCardAsset(this.employeeService.employeeDetail.assetsCurrent);
      });
    });
  }
  /**
   * This function takes an array of assets and returns an array of fuel card assets
   * @param assetsArray - The array of assets that you want to filter.
   */
  getCardAsset(assetsArray) {
    this.myCards = assetsArray.filter(asset => asset.extendable?.__typename === 'FuelCardAsset');
    this.cardContactList = this.myCards;
  }
  openDialogWithTemplateRef(templateRef: TemplateRef<any>, pinCode) {
    this.dialog.open(templateRef, { data: pinCode });
  }
  closeDialog() {
    this.dialog.closeAll();
  }
  download(documents) {
    this.employeeService.downloadDocument(documents, 'OTHER');
  }
}
