<h1 class="text-xl font-semibold border-slate-200 border-b-2 text-primary-600" *ngIf="contacts.length">
  {{ 'MyGarage.Contacts' | transloco }}
</h1>
<div class="grid grid-cols-1 w-full mt-2" *ngIf="contacts.length">
  <div class="w-full bg-white flex flex-row flex-wrap" *ngFor="let contact of contacts">
    <div class="w-full">
      <!-- Profile Card -->
      <div class="rounded-lg w-full flex flex-row flex-wrap">
        <div>
          <img
            *ngIf="contact.serviceProvider.logo"
            src="{{ contact.serviceProvider.logo }}"
            alt="Card cover image"
            class="h-16 w-16 mr-4"
          />
          <mat-icon class="h-16 w-16" *ngIf="!contact.serviceProvider.logo">dashboard</mat-icon>
        </div>
        <div class="md:w-2/3 w-full px-3 flex flex-row flex-wrap">
          <div class="grid grid-cols-1">
            <div class="col-span-1 text-xl leading-tight text-primary-600 font-semibold">
              {{ contact.serviceProvider.name }}
            </div>
            <button
              class="bg-[#FFECD4] text-slate-500 text-lg active:bg-white px-2 rounded mr-4 mb-1 mt-2"
              type="button"
            >
              {{ 'MyGarage.ServiceType.' + contact?.serviceType | transloco }}
            </button>
          </div>
        </div>
      </div>
      <!-- End Profile Card -->
      <div
        class="bg-white pt-3 pb-2 rounded-lg"
        [ngClass]="splitColumns ? 'divide-x grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2' : 'divide-y'"
      >
        <div class="grid grid-cols-1 p-2">
          <div *ngIf="contact?.guideline?.length">
            <div class="word-breck text-primary-600 font-bold text-base pb-2">
              {{ 'MyGarage.Guidline' | transloco }}
            </div>
            <div class="word-breck text-base">
              {{ contact?.guideline }}
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1 p-2">
          <div class="text-base pb-3" *ngFor="let item of contact.contacts">
            <div class="flex items-center mb-4 w-full">
              <div class="flex-1 flex-auto justify-center">
                <div class="text-xl text-primary font-semibold leading-tight mb-2">
                  {{ item.firstName }} {{ item.lastName }}
                </div>
                <div class="word-breck text-md text-secondary leading-tight mb-2">
                  {{ item.email }}
                </div>
                <div class="word-breck text-md text-secondary leading-tight mb-2">
                  {{ item.phoneNumber }}
                </div>
              </div>
              <div class="flex justify-center space-x-4">
                <a
                  href="mailto:{{ item.email }}"
                  mat-icon-button
                  aria-label="Example icon button with a home icon"
                  class="text-white bg-primary"
                >
                  <mat-icon class="text-white">email</mat-icon>
                </a>
                <a
                  href="tel:{{ item.phoneNumber }}"
                  mat-icon-button
                  aria-label="Example icon button with a home icon"
                  class="text-white bg-primary"
                >
                  <mat-icon class="text-white">call</mat-icon>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
