import { Component, OnInit } from '@angular/core';
import { EmployeeService } from 'app/shared/employee.service';
import { UserService } from 'app/shared/user.service';
import { MatDialog } from '@angular/material/dialog';
import { UploadDocumentComponent } from '../upload-document/upload-document.component';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  activeUser: any = {};
  policyDocuments = [];
  identityCards = [];
  otherDocuments = [];
  drivingLicences = [];
  panelOpenState = false;
  showAddress = true;
  constructor(public userService: UserService, public employeeService: EmployeeService, public dialog: MatDialog) {}
  ngOnInit(): void {
    this.userService.fetchUserData().subscribe(result => {
      this.activeUser = result.data.me;
      this.employeeService.getEmployeeDetailById(this.activeUser?.id).subscribe((result: any) => {
        this.employeeService.employeeDetail = result?.data?.userById;
        if (
          !this.employeeService.employeeDetail.street &&
          !this.employeeService.employeeDetail.number &&
          !this.employeeService.employeeDetail.bus &&
          !this.employeeService.employeeDetail.street &&
          !this.employeeService.employeeDetail.city &&
          !this.employeeService.employeeDetail.country
        ) {
          this.showAddress = false;
        }
        this.filterDocument(this.employeeService.employeeDetail.documents);
      });
    });
  }
  downloadDocument(url) {
    if (url) {
      window.open(url, '_self');
    }
  }
  filterDocument(documents) {
    documents.map(document => {
      switch (document.type) {
        case 'COMPANY_POLICY':
          this.policyDocuments.push(document);
          break;
        case 'ID':
          this.identityCards.push(document);
          break;
        case 'DRIVER_LICENSE':
          this.drivingLicences.push(document);
          break;
        case 'OTHER':
          this.otherDocuments.push(document);
          break;
        default:
          break;
      }
    });
  }
  openDialog() {
    const dialogRef = this.dialog.open(UploadDocumentComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
