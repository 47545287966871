import { gql } from 'apollo-angular';
export const ORDER_CONFIGURATION_BY_ID_QUERY = gql`
  query orderConfigurationById($id: ID!) {
    orderConfiguration(id: $id) {
      id
      status
      declineReason
      asset {
        id
        extendable {
          ... on CarAsset {
            brand
            model
            duration
            leaseDuration
            optionsPrice
            cataloguePrice
            CO2EmissionNEDC
            CO2EmissionWLTP
            batteryCapacity
            electricityConsumption
            enginePower
            id
            thumbUrl
            typeOwnership
          }
        }
        isVirtual
      }
      documents {
        name
        url
        downloadUrl
        creator {
          id
          firstName
          lastName
        }
        id
      }
      tcoCalculation {
        benefitInKind
        id
        taxDeduction
        type
        value
      }
    }
  }
`;
