import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { CREATE_DOCUMENT } from 'app/graphQL/createDocument';
import { EMPLOYEE_DETAILS } from 'app/graphQL/employeeDetails';
import { FUEL_TYPE } from 'app/graphQL/fuelType';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'app/core/auth/auth.service';
import { EnvironmentService } from '../../environments/environment.service';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { CAR_BRAND_INFO, CAR_INFO, CAR_MODEL_INFO, CAR_NAME_INFO } from 'app/graphQL/assetsDetail';
import { CarBrand } from 'app/interfaces/carBrand.interface';
import { CarModel } from 'app/interfaces/carModel.interface';
import { CarName } from 'app/interfaces/carName.interface';
import { CREATE_CONFIGURATION_QUERY } from 'app/graphQL/order/createOrderConfiguration';
import { ORDER_CONFIGURATION_BY_ID_QUERY } from 'app/graphQL/order/orderConfigurationById';
import { ORDER_CONFIGURATION_APPROVE_EMPLOYEE } from 'app/graphQL/order/orderConfigurationApprove';
@Injectable({
  providedIn: 'root',
})
export class EmployeeService {
  public employeeDetail;
  public employeeOrder = new BehaviorSubject<boolean>(true);
  constructor(
    private apollo: Apollo,
    private http: HttpClient,
    private _authService: AuthService,
    private readonly environment: EnvironmentService,
  ) {}
  private httpOptions = {
    headers: new HttpHeaders({
      Authorization: 'Bearer ' + this._authService.accessToken,
    }),
  };
  getEmployeeDetailById(id) {
    return this.apollo.watchQuery({
      query: EMPLOYEE_DETAILS,
      fetchPolicy: 'cache-and-network',
      variables: { userId: id },
    }).valueChanges;
  }
  diff_months(start, end) {
    const startDate = new Date(start);
    const endDate = new Date(end);

    const yearsDiff = endDate.getFullYear() - startDate.getFullYear();
    const monthsDiff = endDate.getMonth() - startDate.getMonth();
    const daysDiff = endDate.getDate() - startDate.getDate();
    let totalMonths = yearsDiff * 12 + monthsDiff;
    let remainingDays = daysDiff;

    if (remainingDays < 0) {
      totalMonths--;
      const daysInLastMonth = new Date(endDate.getFullYear(), endDate.getMonth(), 0).getDate();
      remainingDays = daysInLastMonth + remainingDays;
    }

    return { totalMonths, remainingDays };
  }
  getFuleType() {
    return this.apollo.watchQuery({
      query: FUEL_TYPE,
      fetchPolicy: 'cache-and-network',
      variables: { forCarBenefitOnly: false },
    }).valueChanges;
  }
  uploadDocument(docInput, file) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('command', 'DocumentUpload');
      formData.append('Content-Type', file[0].type);
      formData.append('file', file[0]);
      this.http
        .post<any>(this.environment.apiUrl.split('/graphql')[0] + '/api/upload-file', formData, this.httpOptions)
        .subscribe(uploadFileResponse => {
          docInput.path = uploadFileResponse.path;
          this.addDoucment(docInput).subscribe(
            ({ data }) => {
              resolve(data);
            },
            error => {
              reject(error);
            },
          );
        });
    });
  }
  addDoucment(docInput) {
    return this.apollo.mutate({
      mutation: CREATE_DOCUMENT,
      variables: { input: docInput },
    });
  }
  downloadDocument(documents, type) {
    let downloadDocument;
    if (!documents.length) {
      return;
    }
    downloadDocument = documents.filter(document => document.type == type);

    if (downloadDocument.length) {
      this.downloadAll(downloadDocument);
    }
  }
  downloadAll(urls) {
    var link = document.createElement('a');
    link.setAttribute('download', null);
    link.setAttribute('target', '_blank');
    link.style.display = 'none';
    document.body.appendChild(link);
    for (var i = 0; i < urls.length; i++) {
      link.setAttribute('href', urls[i].downloadUrl);
      link.click();
    }
    document.body.removeChild(link);
  }
  getCarBrandInfo(): Observable<CarBrand> {
    return this.apollo
      .watchQuery<CarBrand>({
        query: CAR_BRAND_INFO,
        fetchPolicy: 'cache-and-network',
      })
      .valueChanges.pipe(map(({ data }) => data));
  }
  getCarModelInfo(brand): Observable<CarModel> {
    return this.apollo
      .watchQuery<CarModel>({
        query: CAR_MODEL_INFO,
        fetchPolicy: 'cache-and-network',
        variables: { brand: brand },
      })
      .valueChanges.pipe(map(({ data }) => data));
  }
  getCarNameInfo(brandValue, modelValue): Observable<CarName> {
    return this.apollo
      .watchQuery<CarName>({
        query: CAR_NAME_INFO,
        fetchPolicy: 'cache-and-network',
        variables: {
          brand: brandValue,
          model: modelValue,
        },
      })
      .valueChanges.pipe(map(({ data }) => data));
  }
  getCarInfo(brand, modelvalue, nameValue) {
    return this.apollo.watchQuery({
      query: CAR_INFO,
      variables: {
        brand: brand,
        model: modelvalue,
        name: nameValue,
      },
    }).valueChanges;
  }
  createConfiguration(configurationDetail): Observable<any> {
    return this.apollo.mutate({
      mutation: CREATE_CONFIGURATION_QUERY,
      variables: { input: configurationDetail },
    });
  }
  orderConfigurationById(Id): Observable<any> {
    return this.apollo.watchQuery({
      query: ORDER_CONFIGURATION_BY_ID_QUERY,
      variables: { id: Id },
    }).valueChanges;
  }
  orderConfigurationApproveEmployee(Id): Observable<any> {
    return this.apollo.mutate({
      mutation: ORDER_CONFIGURATION_APPROVE_EMPLOYEE,
      variables: {
        input: {
          id: Id,
        },
      },
    });
  }
}
