/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
  {
    id: 'personal_data',
    title: 'Personal data',
    type: 'basic',
    icon: 'mat_outline:person',
    link: '/persondata',
  },
  {
    id: 'my_garage',
    title: 'My Garage',
    type: 'basic',
    icon: 'mat_outline:directions_car_filled',
    link: '/mygarage',
  },
  {
    id: 'service',
    title: 'My Cards',
    type: 'basic',
    icon: 'mat_outline:credit_card',
    link: '/cardservices',
  },
  {
    id: 'order',
    title: 'Ordering',
    type: 'basic',
    icon: 'mat_solid:border_color',
    link: '/order',
    badge: {
      title: '',
      classes: '',
    },
  },
];
export const compactNavigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Overview',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example',
  },
];
export const futuristicNavigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Overview',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example',
  },
];
export const horizontalNavigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Overview',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example',
  },
];
