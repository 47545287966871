import { gql } from 'apollo-angular';
export const CREATE_CONFIGURATION_QUERY = gql`
  mutation CreateConfiguration($input: CreateOrderConfigurationInput!) {
    createOrderConfiguration(input: $input) {
      id
      order {
        id
        orderStatus
      }
      tcoCalculation {
        id
        value
      }
      documents {
        id
        name
        creator {
          id
          firstName
          lastName
        }
        downloadUrl
        extension
        size
        type
        url
      }
      status
    }
  }
`;
