<div class="flex flex-col flex-auto min-w-0">
  <div class="flex-auto p-6 sm:p-10">
    <div class="xl:p-10 h-300 min-h-300 max-h-300 rounded-2xl sm:p-0">
      <div class="container mx-auto">
        <div class="">
          <!-- vehicle selection Row -->
          <div class="flex flex-wrap">
            <div class="w-full">
              <form [formGroup]="carInfoModelForm">
                <div class="flex flex-wrap">
                  <div class="lg:w-1/3 md:w-1/3 sm:w-full w-full px-4">
                    <mat-form-field class="w-full">
                      <mat-select
                        placeholder="{{ 'Dialog.PlaceholderSelectBrand' | transloco }}"
                        formControlName="carBrand"
                        (selectionChange)="onBrandChange($event)"
                      >
                        <mat-option *ngFor="let brand of this.carBrandList" [value]="brand">{{ brand }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="lg:w-1/3 md:w-1/3 sm:w-full w-full px-4">
                    <mat-form-field class="w-full">
                      <mat-select
                        placeholder="{{ 'Dialog.PlaceholderSelectModel' | transloco }}"
                        formControlName="carModel"
                        (selectionChange)="onModelChange($event)"
                      >
                        <mat-option *ngFor="let model of this.carModelList" [value]="model">{{ model }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="lg:w-1/3 md:w-1/3 sm:w-full w-full px-4">
                    <mat-form-field class="w-full">
                      <mat-select
                        placeholder="{{ 'Dialog.PlaceholderSelectType' | transloco }}"
                        formControlName="carName"
                        (selectionChange)="onNameChange($event)"
                      >
                        <mat-option *ngFor="let carName of this.carNameList" [value]="carName">{{
                          carName
                        }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </form>
            </div>
            <div class="w-full">
              <mat-divider></mat-divider>
              <div class="text-primary text-xl font-semibold p-5">{{ 'Orders.label.modelInfo' | transloco }}</div>
              <form [formGroup]="carModelDataForm">
                <div class="flex flex-wrap">
                  <div class="lg:w-1/3 md:w-1/3 sm:w-full w-full px-4">
                    <div class="font-medium text-primary mb-1">{{ 'Orders.label.fuelType' | transloco }}</div>
                    <mat-form-field class="w-full">
                      <mat-select
                        placeholder="{{ 'Dialog.PlaceholderSelectType' | transloco }}"
                        formControlName="fuelType"
                      >
                        <mat-option *ngFor="let fuel of this.fuelTypeList" [value]="fuel.name">{{
                          fuel.name
                        }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div
                    class="lg:w-1/3 md:w-1/3 sm:w-full w-full px-4"
                    *ngIf="!(carModelDataForm.value.fuelType == 'Electricity')"
                  >
                    <div class="font-medium text-primary mb-1">{{ 'Orders.label.fuelconsuption' | transloco }}</div>
                    <mat-form-field class="w-full">
                      <input matInput formControlName="fuelConsumption" pattern="[0-9]*" />
                    </mat-form-field>
                  </div>
                  <div
                    class="lg:w-1/3 md:w-1/3 sm:w-full w-full px-4"
                    *ngIf="carModelDataForm.value.fuelType == 'Electricity'"
                  >
                    <div class="font-medium text-primary mb-1">{{ 'Orders.label.eleConsuption' | transloco }}</div>
                    <mat-form-field class="w-full">
                      <input matInput formControlName="electricityConsumption" pattern="[0-9]*" />
                    </mat-form-field>
                  </div>
                  <!--  -->
                  <div class="lg:w-1/3 md:w-1/3 sm:w-full w-full px-4">
                    <div class="font-medium text-primary mb-1">{{ 'Orders.label.euronorm' | transloco }}</div>
                    <mat-form-field class="w-full">
                      <input matInput formControlName="euronorm" pattern="[0-9]*" />
                    </mat-form-field>
                  </div>
                  <!--  -->
                  <div class="lg:w-1/3 md:w-1/3 sm:w-full w-full px-4">
                    <div class="font-medium text-primary mb-1">{{ 'Orders.label.kw' | transloco }}</div>
                    <mat-form-field class="w-full">
                      <input matInput formControlName="enginePower" pattern="[0-9]*" />
                    </mat-form-field>
                  </div>
                  <!--  -->
                  <div class="lg:w-1/3 md:w-1/3 sm:w-full w-full px-4">
                    <div class="font-medium text-primary mb-1">{{ 'Orders.label.fiscalHp' | transloco }}</div>
                    <mat-form-field class="w-full">
                      <input matInput formControlName="fiscalHorsepower" pattern="[0-9]*" />
                    </mat-form-field>
                  </div>
                  <!--  -->
                  <div class="lg:w-1/3 md:w-1/3 sm:w-full w-full px-4">
                    <div class="font-medium text-primary mb-1">{{ 'Orders.label.wltp' | transloco }}</div>
                    <mat-form-field class="w-full">
                      <input matInput formControlName="CO2EmissionWLTP" pattern="[0-9]*" />
                    </mat-form-field>
                  </div>
                  <!--  -->
                  <div class="lg:w-1/3 md:w-1/3 sm:w-full w-full px-4">
                    <div class="font-medium text-primary mb-1">{{ 'Orders.label.nedc' | transloco }}</div>
                    <mat-form-field class="w-full">
                      <input matInput formControlName="CO2EmissionNEDC" pattern="[0-9]*" />
                    </mat-form-field>
                  </div>
                  <div></div>
                  <div class="w-full text-primary text-xl font-semibold p-5">
                    <mat-divider></mat-divider> {{ 'Orders.label.pricing' | transloco }}
                  </div>
                  <div class="lg:w-1/3 md:w-1/3 sm:w-full w-full px-4">
                    <div class="font-medium text-primary mb-1">{{ 'Orders.label.catalogPrice' | transloco }}</div>
                    <mat-form-field class="w-full">
                      <input matInput formControlName="cataloguePrice" pattern="[0-9]*" />
                    </mat-form-field>
                  </div>
                  <div class="lg:w-1/3 md:w-1/3 sm:w-full w-full px-4">
                    <div class="font-medium text-primary mb-1">{{ 'Orders.label.discount' | transloco }}</div>
                    <mat-form-field class="w-full">
                      <input matInput formControlName="discount" pattern="[0-9]*" />
                    </mat-form-field>
                  </div>
                  <div class="lg:w-1/3 md:w-1/3 sm:w-full w-full px-4" *ngIf="responsiblePerson == 'EMPLOYEE'">
                    <div class="font-medium text-primary mb-1">{{ 'Orders.label.leasePrice' | transloco }}</div>
                    <mat-form-field class="w-full">
                      <input matInput formControlName="leasePrice" pattern="[0-9]*" />
                    </mat-form-field>
                  </div>
                  <div class="lg:w-1/3 md:w-1/3 sm:w-full w-full px-4">
                    <div class="font-medium text-primary mb-1">
                      {{ 'Orders.label.optionPrice' | transloco }}
                    </div>
                    <mat-form-field class="w-full">
                      <input matInput formControlName="optionsPrice" pattern="[0-9]*" />
                    </mat-form-field>
                  </div>
                  <div class="w-full text-primary text-xl font-semibold p-5">
                    {{ 'Orders.label.uploadQuotaion' | transloco }}
                  </div>
                  <div class="w-full px-5 py-2">
                    <input
                      #fileInput
                      type="file"
                      name="file"
                      id="file"
                      (change)="onFileChange($event.target.files)"
                      accept="image/png, image/gif, image/jpeg,video/mp4,video/x-m4v,video/*,application/pdf,application/msword,
                      application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint"
                    />
                  </div>
                  <div class="w-full px-5 py-2" *ngIf="files?.length > 0">
                    <mat-checkbox class="example-margin" (change)="quoteVerify($event.checked)">{{
                      'Orders.label.confirmation' | transloco
                    }}</mat-checkbox>
                  </div>
                  <div class="w-full p-5" *ngIf="files?.length > 0">
                    <button
                      mat-raised-button
                      class="capitalize"
                      color="primary"
                      (click)="calculateConfiguration()"
                      [disabled]="!isConfirmed || carModelDataForm.invalid"
                    >
                      <span>{{ 'Orders.calculate' | transloco }}</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
