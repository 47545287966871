import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, of, switchMap } from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { AuthenticationDetails, CognitoRefreshToken, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { EnvironmentService } from '../../../environments/environment.service';
import { Amplify, Auth } from 'aws-amplify';

@Injectable()
export class AuthService {
  private _authenticated = false;
  private _cognitoPool: CognitoUserPool = null;

  public userEmail = '';
  public forceChangePassword: boolean = false;
  public cognitoUser;
  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _userService: UserService,
    private readonly environment: EnvironmentService,
  ) {
    this._cognitoPool = new CognitoUserPool({
      UserPoolId: environment.awsCognito.userPoolId,
      ClientId: environment.awsCognito.userPoolWebClientId,
    });
    const user = this._userService.user$;
    if (user && this.accessToken) {
      this._authenticated = true;
    }
    Amplify.configure({
      Auth: environment.awsCognito,
      oauth: {
        domain: 'https://mobility-buddy-staging.auth.eu-west-1.amazoncognito.com/',
        scope: ['email', 'openid', 'profile'],
        redirectSignIn: 'http://localhost:4200/sign-in',
        redirectSignOut: 'http://localhost:4200/sign-out',
        responseType: 'code',
      },
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }

  /**
   * Setter & getter for refresh token
   */
  set refreshToken(token: string) {
    localStorage.setItem('refreshToken', token);
  }

  get refreshToken(): string {
    return localStorage.getItem('refreshToken') ?? '';
  }

  getCognito(username: string): CognitoUser {
    const userData = { Username: username, Pool: this._cognitoPool };
    return new CognitoUser(userData);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email: string): Observable<any> {
    return this._httpClient.post('api/auth/forgot-password', email);
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(password: string): Observable<any> {
    return this._httpClient.post('api/auth/reset-password', password);
  }

  refreshSession(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.getCognito(this._userService.user.email).refreshSession(
        new CognitoRefreshToken({ RefreshToken: this.refreshToken }),
        (error, result) => {
          if (error) {
            console.log(error);
            reject(error);
          }
          // handle new accesstoken and refreshtoken in cache
          resolve(true);
        },
      );
    });
  }

  /**
   * Sign in using the access token
   */
  signInUsingToken(): Observable<any> {
    // Sign in using the token
    return this._httpClient
      .post('api/auth/sign-in-with-token', {
        accessToken: this.accessToken,
      })
      .pipe(
        catchError(() =>
          // Return false
          of(false),
        ),
        switchMap((response: any) => {
          if (response.accessToken) {
            this.accessToken = response.accessToken;
          }

          // Set the authenticated flag to true
          this._authenticated = true;

          // Store the user on the user service
          this._userService.user = response.user;

          // Return true
          return of(true);
        }),
      );
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
    return this._httpClient.post('api/auth/sign-up', user);
  }

  /**
   * Unlock session
   *
   * @param credentials
   */
  unlockSession(credentials: { email: string; password: string }): Observable<any> {
    return this._httpClient.post('api/auth/unlock-session', credentials);
  }

  /**
   * Check the authentication status
   */
  check(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this._authenticated) {
        // Check if the user is logged in
        return resolve(true);
      }

      // Check the access token availability
      if (!this.accessToken) {
        return resolve(false);
      }

      // Check the access token expire date
      if (AuthUtils.isTokenExpired(this.accessToken)) {
        // TODO: use refresh token here.
        return resolve(false);
      }
    });
  }

  /*Authentication with Amplify*/

  /* Amplify SignIn */
  public SignIn(credentials: { Username: string; Password: string }): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      Auth.signIn(credentials.Username, credentials.Password)
        .then(result => {
          if (result?.challengeName === 'NEW_PASSWORD_REQUIRED') {
            this.forceChangePassword = true;
            this.cognitoUser = result;
            resolve(result);
          } else {
            this.accessToken = result?.signInUserSession?.accessToken?.jwtToken;
            this.refreshToken = result?.signInUserSession?.refreshToken?.token;
            this._userService.user = {
              id: result?.attributes?.sub,
              name: `${result?.attributes?.given_name} ${result?.attributes?.family_name}`,
              email: result?.attributes?.email,
            };
            this._authenticated = true;
            resolve(result);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  /* Amplify SignOut */
  public SignOut(): Promise<any> {
    return Auth.signOut().then(() => {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('user');
      // Set the authenticated flag to false
      this._authenticated = false;
    });
  }
  public forgotpassword(credentials: { Username: string }): Promise<any> {
    return Auth.forgotPassword(credentials.Username);
  }

  public forgotPasswordSubmit(credentials: { Username: string; code: string; Password: string }): Promise<any> {
    return Auth.forgotPasswordSubmit(credentials.Username, credentials.code, credentials.Password);
  }

  public forceChagePassword(newPassword): Promise<any> {
    if (this.forceChangePassword && this.cognitoUser) {
      return Auth.completeNewPassword(
        this.cognitoUser, // the Cognito User Object
        newPassword, // the new password
      )
        .then(user => {
          // at this time the user is logged in if no MFA required
        })
        .catch(e => {
          console.log(e);
        });
    }
  }
}
