/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
import 'zone.js/dist/zone-error';
import { IEnvironment } from './environment.interface'; // Included with Angular CLI.

const apiUrl = `https://api.dev.mobilitybuddy.be/graphql`;

export const environment: IEnvironment = {
  production: true,
  enableDebugTools: true,
  logLevel: 'info',
  apiUrl,
  awsCognito: {
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_n0qJcFBH5',
    userPoolWebClientId: '2md83v6ihcm2trvard6pa7hpea',
  },
};
