import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';
import { FuseAlertType } from '@fuse/components/alert';
import { EmployeeService } from 'app/shared/employee.service';
import { UserService } from 'app/shared/user.service';
const enum DocumentType {
  DRIVER_LICENSE = 'driver_license',
  ID = 'id',
  COMPANY_POLICY = 'company_policy',
  CONFORMITY_CERTIFICATE = 'conformity_certificate',
  REGISTRATION_CERTIFICATE = 'registration_certificate',
  INSURANCE_CERTIFICATE = 'insurance_certificate',
  OTHER = 'other',
}
@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.scss'],
  animations: fuseAnimations,
})
export class UploadDocumentComponent {
  documentName;
  form: FormGroup;
  assets;
  files: any;
  documentType = [];
  userId;
  alert: { type: FuseAlertType; message: string } = {
    type: 'error',
    message: '',
  };
  showAlert = false;
  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<UploadDocumentComponent>,
    public employeeService: EmployeeService,
    public userService: UserService,
  ) {}
  ngOnInit() {
    this.userService.fetchUserData().subscribe(result => {
      this.userId = result.data.me.id;
    });
    this.assets = this.employeeService.employeeDetail.assetsCurrent;
    this.form = this.formBuilder.group({
      documentName: ['', Validators.required],
      asset: ['', Validators.required],
      docType: ['', Validators.required],
    });
  }
  onCloseDialog() {
    this.dialogRef.close();
  }
  submit() {
    let documentInput;
    if (this.files == undefined || !this.files.length) {
      this.showAlert = true;
      this.alert = {
        type: 'error',
        message: 'Please upload document.',
      };
      return;
    } else {
      this.showAlert = false;
    }
    documentInput = {
      asset: this.form.value.asset.id,
      company: this.employeeService.employeeDetail.companies[0].id,
      name: this.form.value.documentName,
      path: '',
      type: this.form.value.docType.toUpperCase(),
    };
    if (this.form.value.asset == 'PERSONAL_DOCUMENT') {
      documentInput.employee = this.userId;
    }
    this.employeeService
      .uploadDocument(documentInput, this.files)
      .then(() => {
        this.form.reset();
        this.files = [];
        this.dialogRef.close();
      })
      .catch(error => {
        console.log('error while upload Document:', error);
      });
  }
  onFileChange(uploadedFile: File) {
    this.files = uploadedFile;
    this.showAlert = false;
  }
  deleteFile(): void {
    this.files = [];
  }

  onAssetsChange(asset) {
    if (asset.value === 'PERSONAL_DOCUMENT') {
      this.documentType = [
        DocumentType.COMPANY_POLICY,
        DocumentType.DRIVER_LICENSE,
        DocumentType.ID,
        DocumentType.OTHER,
      ];
      return;
    }
    switch (asset.value.extendable?.__typename) {
      case 'CarAsset':
        this.documentType = [
          DocumentType.CONFORMITY_CERTIFICATE,
          DocumentType.REGISTRATION_CERTIFICATE,
          DocumentType.INSURANCE_CERTIFICATE,
          DocumentType.OTHER,
        ];
        this.employeeService.employeeDetail.companies[0].id;
        break;
      case 'BicycleAsset':
        this.documentType = [DocumentType.OTHER];
        break;
      case 'ChargingSystemAsset':
        this.documentType = [DocumentType.OTHER];
        break;
      case 'FuelCardAsset':
        this.documentType = [DocumentType.OTHER];
        break;
      default:
        break;
    }
  }
}
