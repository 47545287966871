import { gql } from 'apollo-angular';
export const EMPLOYEE_DETAILS = gql`
  query Employee($userId: ID!) {
    userById(id: $userId) {
      assetsCurrent {
        documents {
          createdAt
          creator {
            firstName
            id
            lastName
          }
          downloadUrl
          id
          name
          type
          url
        }
        currentEmployee {
          firstName
          id
          lastName
        }
        serviceContacts{
          contacts{
            email
            phoneNumber
            firstName
            lastName
            id
          }
          guideline
          serviceType
          serviceProvider{
            id
            logo
            name
          }
          id
        }
        serviceCollections{
          id
          serviceContacts{
            contacts{
            email
            phoneNumber
            firstName
            lastName
            id
          }
          guideline
          serviceType
          serviceProvider{
            id
            logo
            name
          }
          id
          }
        }
        extendable {
          __typename
          ... on BicycleAsset {
            brand
            id
            model
            serialNumber
            typeOwnership
            leaseStartDate
            purchaseStartDate
            duration
            leaseDuration
          }
          ... on CarAsset {
            brand
            id
            licensePlate
            model
            vin
            licensePlate
            registrationDate
            fuelType
            fuelConsumption
            CO2EmissionWLTP
            registrationDate
            typeOwnership
            leaseStartDate
            purchaseStartDate
            duration
            leaseDuration
          }
          ... on FuelCardAsset {
            id
            number
            expiryDate
            pinCode
          }
          ... on ChargingSystemAsset {
            id
            serialNumber
            maxChargingLoad
            manufacturer
          }
        }
        id
        pivot {
          endDate
          id
          startDate
        }
        serviceProvider {
          id
          name
        }
      }
      assetsPast {
        currentEmployee {
          firstName
          id
          lastName
        }
        extendable {
          __typename
          ... on BicycleAsset {
            brand
            id
            model
            serialNumber
          }
          ... on CarAsset {
            brand
            id
            licensePlate
            model
            vin
          }
          ... on FuelCardAsset {
            id
            number
          }
          ... on ChargingSystemAsset {
            id
            serialNumber
          }
        }
        id
        pivot {
          endDate
          id
          startDate
        }
        serviceProvider {
          id
          name
        }
      }
      category {
        bicycleBenefit {
          allowLease
          allowPurchase
          id
          leaseBudget
          purchaseBudget
        }
        carBenefit {
          allowLease
          allowPurchase
          id
          leaseBudget
          purchaseBudget
        }
        chargingSystemBenefit {
          budget
          id
        }
        color
        fuelCardBenefit {
          id
          monthlyBudget
        }
        id
        name
      }
      companies {
        id
        name
        contactMailbox
        contactFleetManager {
          id
          firstName
          lastName
          phoneNumber
        }
      }
      documents {
        createdAt
        creator {
          firstName
          id
          lastName
        }
        downloadUrl
        id
        name
        type
        url
      }
      email
      employmentStatus
      firstName
      hireDate
      id
      lastName
      phoneNumber
      terminationDate
      id
      bus
      city
      country {
        code
        id
        name
      }
      number
      postalCode
      street
    }
  }
`;
