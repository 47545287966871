import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { UserService } from 'app/shared/user.service';
import { EmployeeService } from 'app/shared/employee.service';

@Component({
  selector: 'classy-layout',
  templateUrl: './classy.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ClassyLayoutComponent implements OnInit, OnDestroy {
  isScreenSmall: boolean;
  navigation: Navigation;
  orderStatus;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _navigationService: NavigationService,
    public _userService: UserService,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fuseNavigationService: FuseNavigationService,
    public employeeService: EmployeeService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for current year
   */
  get currentYear(): number {
    return new Date().getFullYear();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._userService.fetchUserData().subscribe(fetchResult => {
      if (fetchResult?.data.me?.orders.length) {
        this.orderStatus = fetchResult?.data.me?.orders[0].orderStatus;
        this.employeeService.employeeOrder.next(true);
      } else {
        this.employeeService.employeeOrder.next(false);
      }
      this.employeeService.getEmployeeDetailById(fetchResult.data.me?.id).subscribe((employeeResult: any) => {
        this.employeeService.employeeDetail = employeeResult?.data?.userById;
      });
    });
    // Subscribe to navigation data
    this._navigationService.navigation$.pipe(takeUntil(this._unsubscribeAll)).subscribe((navigation: Navigation) => {
      this.navigation = navigation;
      this.employeeService.employeeOrder.subscribe(hasOrder => {
        if (!hasOrder) {
          const index = navigation.default.findIndex(obj => obj['id'] === 'order');
          if (index !== -1) {
            navigation.default.splice(index, 1);
          }
        } else {
          const navComponent =
            this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');
          const orderingDashboardItem = this._fuseNavigationService.getItem('order', navigation.default);
          if (this.orderStatus == 'INVITED' || this.orderStatus == 'CONFIGURING') {
            orderingDashboardItem.badge.classes = 'bg-red-600 px-2 w-4 rounded-full h-4 mr-28';
            navComponent?.refresh();
          } else {
            orderingDashboardItem.badge.classes = '';
            navComponent?.refresh();
          }
        }
      });
    });

    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes('md');
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle navigation
   *
   * @param name
   */
  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }
}
