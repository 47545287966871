import { Pipe, PipeTransform } from '@angular/core';

const enum DocumentType {
  DRIVER_LICENSE = 'driver_license',
  ID = 'id',
  COMPANY_POLICY = 'company_policy',
  CONFORMITY_CERTIFICATE = 'conformity_certificate',
  REGISTRATION_CERTIFICATE = 'registration_certificate',
  INSURANCE_CERTIFICATE = 'insurance_certificate',
  OTHER = 'other',
}

@Pipe({
  name: 'doctype',
})
export class DoctypePipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case DocumentType.DRIVER_LICENSE:
        return 'DriverLicense';
      case DocumentType.COMPANY_POLICY:
        return 'CompanyPolicy';
      case DocumentType.ID:
        return 'IdentityCard';
      case DocumentType.OTHER:
        return 'Other';
      case DocumentType.CONFORMITY_CERTIFICATE:
        return 'ConformityCertificate';
      case DocumentType.REGISTRATION_CERTIFICATE:
        return 'RegistrationCertificate';
      case DocumentType.INSURANCE_CERTIFICATE:
        return 'InsuranceCertificate';

      default:
        return '';
    }
  }
}
