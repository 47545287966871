import { Injectable } from '@angular/core';
import { CanMatch, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { from, Observable, of, switchMap } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class NoAuthGuard implements CanMatch {
  /**
   * Constructor
   */
  constructor(private _authService: AuthService, private _router: Router) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Can match
   *
   * @param route
   * @param segments
   */
  canMatch(route: Route, segments: UrlSegment[]): Promise<boolean | UrlTree> {
    return this._check();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Check the authenticated status
   *
   * @private
   */
  private _check(): Promise<boolean> {
    //return from(this._awsService.isAuthenticated().then(authenticated => {return !authenticated}))
    // Check the authentication status and return an observable of
    // "true" or "false" to allow or prevent the access

    return this._authService.check().then(authenticated => {
      return !authenticated;
    });

    /**
        return this._authService.check().pipe(
            switchMap((authenticated) => of(!authenticated))
        );*/
  }
}
