import { Component } from '@angular/core';
import { EnvironmentService } from '../environments/environment.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  /**
   * Constructor
   */
  constructor(private readonly environment: EnvironmentService) {}
}
